import moment from "moment";
import { APIResponse } from "../../node/src/shared/shared";

export function formData (data: { [key: string]: any }) {
    var form, i, key, keys, len;
    form = new FormData;
    keys = Object.keys(data);
    for (i = 0, len = keys.length; i < len; i++) {
        key = keys[i];
        if (typeof data[key] === 'string' || data[key] instanceof File || data[key] instanceof Blob) {
            form.append(key, data[key]);
        } else {
            form.append(key, JSON.stringify(data[key]));
        }
    }
    return form;
};

export function spreader (a?:any, b?:any, c?:any):any {
    return Object.assign({}, a, b, c);
};

export function create (exp?: string, text?: string): HTMLElement {
    var _, classes, e, elem, id, props, ref;
    elem = (exp.match(/^[a-z0-9-]+/i) || ["div"])[0];
    e = document.createElement(elem);
    id = (exp.match(/#[a-z0-9-]+/i) || [])[0];
    if (id) {
        e.id = id.substr(1);
    }
    classes = exp.match(/\.[a-z0-9-]+/ig) || [];
    e.className = classes.map(function (c) {
        return c.substr(1);
    }).join(' ');
    ref = exp.match(/\[(.+)\]$/) || [], _ = ref[0], props = ref[1];
    (props + ",null=null").replace(/([a-z0-9-]+)=(.*?)(?=,[a-z0-9-]+=)/ig, function (_, key, val) {
        var v;
        v = val;
        if (v.length >= 2 && v[0] === '"' && v[v.length - 1] === '"') {
            v = JSON.parse(v);
        }
        return e.setAttribute(key, v);
    });
    if (text) {
        e.innerText = text;
    }
    return e;
};
export const Utils = {
    pathJoin: function (parts: string[], sep: string = "/"): string {
        var seperator: string = sep || "/";
        var replace: RegExp = new RegExp(seperator + "{1,}", "g");
        return parts.join(seperator).replace(replace, seperator);
    },
    reset: function (): void {
        var children = document.querySelectorAll("body>*");
        children.forEach(function (elem) {
            if (!elem.getAttribute("protected")){
                elem.remove();
            }
        });
    },
    isMobile: {
        Android: function (): boolean {
            return Boolean(navigator.userAgent.match(/Android/i));
        },
        BlackBerry: function (): boolean {
            return Boolean(navigator.userAgent.match(/BlackBerry/i));
        },
        iPadPro: function (): boolean {
            var resolutions: string[] = [
                "2732,2048",
                "2048,2732",
                "2388,1668",
                "1668,2388",

                "2048,1536",
                "1536,2048",
                "2224,1668",
                "1668,2224"
            ]
            var resolution: string = window.devicePixelRatio * window.screen.width + "," + window.devicePixelRatio * window.screen.height
            return resolutions.indexOf(resolution) > -1
        },
        iOS: function (): boolean {
            return Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i));
        },
        Opera: function (): boolean {
            return Boolean(navigator.userAgent.match(/Opera Mini/i));
        },
        Windows: function (): boolean {
            return Boolean(navigator.userAgent.match(/IEMobile/i)) || Boolean(navigator.userAgent.match(/WPDesktop/i));
        },
        any: function (): boolean {
            return Utils.isMobile.Android() || Utils.isMobile.BlackBerry() || Utils.isMobile.iPadPro() || Utils.isMobile.iOS() || Utils.isMobile.Opera() || Utils.isMobile.Windows();
        }
    },
    openApp: function (url: string): void {
        window.open("https://kb9m1.csb.app/?" + encodeURI(url), "_blank");
    },
    openURL: function (url: string): void {
        var a = document.createElement('a');
        console.log(url.replace(/\/$/, "").replace(/^((https?:\/\/)|\/)/, "http://"));
        a.setAttribute("href", url.replace(/\/$/, "").replace(/^((https?:\/\/)|\/)/, "http://"));
        a.setAttribute("target", "_blank");
        a.click()
    },
    limitArray: function limitArray<T>(arr: T[], len: number): T[] {
        if (arr.length <= len) return arr;
        var c: number = 0;
        return arr.filter(function () {
            c++;
            return c <= len;
        });
    },
    safeScroll: function (elem: Element): void {
        return; // Disable for now
        function check() {
            if (elem.scrollTop == 0) elem.scrollTop = 1;
            else if (elem.scrollHeight == elem.scrollTop + elem.getBoundingClientRect().height) elem.scrollTop -= 1;
        }
        check();
        elem.addEventListener("scroll", function () {
            clearTimeout((<any>elem)._timeout);
            (<any>elem)._timeout = setTimeout(check, 60);
        });
    },
    deepCompareObjects: function (a: any, b: any): boolean {
        var leftChain: any[] = [], rightChain: any[] = [];
        function compare2Objects(x: any, y: any): boolean {
            if (isNaN(x) && isNaN(y) && typeof x == 'number' && typeof y == 'number') return true;
            if (x == y) return true;
            if (typeof x == 'function' && typeof y == 'function' || (x instanceof Date && y instanceof Date) || (x instanceof RegExp && y instanceof RegExp) || (x instanceof String && y instanceof String) || (x instanceof Number && y instanceof Number)) return x.toString() == y.toString();
            if (!(x instanceof Object && y instanceof Object)) return false;
            if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) return false;
            if (x.constructor != y.constructor) return false;
            if (x.prototype != y.prototype) return false;
            if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) return false;
            for (var p of Object.keys(y)) {
                if (y.hasOwnProperty(p) != x.hasOwnProperty(p)) return false;
                else if (typeof y[p] != typeof x[p]) return false;
            }
            for (p of Object.keys(x)) {
                if (y.hasOwnProperty(p) != x.hasOwnProperty(p)) return false;
                else if (typeof y[p] != typeof x[p]) return false;
            }
            switch (typeof x[p]) {
                case 'object':
                case 'function':
                    leftChain.push(x);
                    rightChain.push(y);
                    if (!compare2Objects(x[p], y[p])) return false;
                    leftChain.pop();
                    rightChain.pop();
                default:
                    if (x[p] != y[p]) return false;
            }
            return true;
        }
        if (arguments.length < 1) return true;
        for (var i: number = 0; i < arguments.length; i++) {
            if (i == 0) continue;
            leftChain = []; rightChain = [];
            if (!compare2Objects(arguments[0], arguments[i])) return false;
        }
        return true;
    }
};
export const Display = {
    date: function (m: any, short: boolean = false, duration: number = 0): string {
        if (duration) {
            var d1: string = Display.date(m, short);
            if (!short && d1.indexOf("/") > -1) d1 = d1.split(", ")[1];
            d1 = d1.replace(/([A-Z][a-z]{2})([a-z][a-z]+)/g, function (m, g1): string {
                if (g1 == "Tod") return "Today";
                else return g1;
            });
            var d2: string = Display.date(moment(m).valueOf() + duration, short);
            if (!short && d2.indexOf("/") > -1) {
                d2 = d2.split(", ")[1];
            }
            d2 = d2.replace(/([A-Z][a-z]{2})([a-z][a-z]+)/g, function (m, g1): string {
                return g1 == "Tod" ? "Today" : g1;
            });
            var m1 = d1.match(/(.*)(?= \d?\d:\d\d [AP]M)/ig);
            var m2 = d2.match(/(.*)(?= \d?\d:\d\d [AP]M)/ig);
            if (m1[0] == m2[0]) {
                d2 = d2.replace(/(.*)(?= \d?\d:\d\d [AP]M)/ig, "");
            }
            if (m1[0] == "Today at") {
                d2 = d2.replace(/(.*)(?= \d?\d:\d\d [AP]M)/ig, "");
            }
            d1 = d1.replace(/ at /, " ");
            d2 = d2.replace(/ at /, " ");
            console.log(m1, m2, d1, d2);
            return d1 + ' - ' + d2;
        }
        if (short) {
            var cal: string = moment(m).calendar();
            cal = cal.split(" at ")[0];
            return cal;
        } else {
            var cal: string = moment(m).calendar();
            if (cal.indexOf("M") == -1) {
                var t: string = moment(m).format("h:mm A");
                if (t != "0:00 AM") cal += " " + t;
            }
            if (cal.indexOf("/") > -1) {
                if (moment(m).format("yyyy") == moment().format("yyyy"))
                    cal = moment(m).format("ddd") + ", " + cal.replace(/\/\d\d\d\d/g, "");
                else
                    cal = moment(m).format("ddd") + ", " + cal;
            }
            return cal;
        }
    },
    number: function (x: number): string {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
};

//
var Base = "/", Key = "";
export function API (base: string, key: string) {
    Base = base; Key = key;
    return async function (hook: string, options: any): Promise<any> {
        if (options && hook) {
            var res: Response = await fetch(Utils.pathJoin([Base, hook]), {
                method: "POST",
                headers: { 'x-api-key': Key },
                body: formData(options)
            });
            var json: APIResponse = await res.json()
            console.log(json);
            if (json.status) return json.data;
            else {
                if (options.strict && json.message == "Unauthorized") localStorage.clear()
                await localStorage.clear();
                throw new Error(json.message);
            }
        } else {
            throw new Error("Missing Options");
        }
    }
}
//

export var colorscheme:string = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
window.matchMedia("(prefers-color-scheme: dark)").addListener(function (query) {
    colorscheme = query.matches ? "dark" : "light";
    window.dispatchEvent(new CustomEvent('colorscheme', { detail: colorscheme }));
    if ((<any>window).oncolorscheme) (<any>window).oncolorscheme(colorscheme);
});

export var visibility:"visible"|"hidden"|"prerender" = document.visibilityState;
var lastvisibilitystate:"visible"|"hidden"|"prerender" = document.visibilityState;
//window.onvisibilitychange
var hasvisibilityevent = false

window.addEventListener ("visibilitychange", function(){
    hasvisibilityevent = true
    if((<any>window).onvisibilitychange) {
        (<any>window).onvisibilitychange(document.visibilityState);
    }
    console.log(document.visibilityState);
});
setInterval(function(){
    if (document.visibilityState != lastvisibilitystate){
        if((<any>window).onvisibilitychange && !hasvisibilityevent) (<any>window).onvisibilitychange(document.visibilityState);
    }
    lastvisibilitystate = document.visibilityState;
},500);

export function getFileAsync():Promise<File> {
  return new Promise(function(resolve, reject) {
    var cancel:Function, input:HTMLInputElement;
    input = create("input.hidden-file-input") as HTMLInputElement;
    input.type = "file";
    input.accept = "image/*";
    document.body.appendChild(input);
    input.addEventListener("change", function(e) {
      document.removeEventListener("mousemove", ()=>cancel());
      if ((e.target as HTMLInputElement).files[0]) {
        return resolve((e.target as HTMLInputElement).files[0]);
      }
      return reject();
    });
    input.click();
    cancel = function() {
        document.body.removeChild(input);
      reject();
      return document.removeEventListener("mousemove", ()=>cancel());
    };
    return document.addEventListener("mousemove", ()=>cancel());
  });
};




export const REPs:string[] = [
    "AMBIT",
    "AMERIPOWER",
    "AMIGO",
    "ANDLER",
    "APG&E",
    "BOUNCE",
    "BRILLIANT",
    "CHAMPION",
    "CIRRO",
    "CON-ED",
    "CONSTELLATION",
    "CPL",
    "DIRECT",
    "DISCOUNT",
    "ENCOA",
    "ENGIE",
    "ENTRUST",
    "FIRST CHOICE",
    "FRONTIER",
    "GDF SUEZ",
    "GEXA",
    "GREEN MOUNTAIN",
    "HINO",
    "HINO ELECTRIC",
    "HUDSON",
    "INFINITE ENERGY",
    "JUST ENERGY",
    "LIBERTY",
    "MID-AMERICAN",
    "MP2 ENERGY",
    "NUECES ELECTRIC",
    "ONPAC",
    "OUR ENERGY",
    "PENNY WISE",
    "RELIANT",
    "SOURCE",
    "SPARK",
    "STARTEX",
    "STREAM",
    "SUMMER ENERGY",
    "TARA",
    "TEXANS ENERGY",
    "TEXPO",
    "THINK",
    "TXU",
    "V247 POWER",
    "XOOM"
];

/*export function SimpleList (config:any) {
  CONFIG = {
    buffer: 100,
    marginTop: 0,
    marginBottom: 0
    elementheight: 50,
    getlength: -> 0,
    getelementfor: (i) -> # promise or function
      elem = document.createElement "div"
      elem.innerText = "#"+i
      elem
  }
  LIST = config.container || document.createElement "div"
  rendered = []

  bottom = document.createElement "div"
  bottom.style.position = "absolute"
  bottom.style['min-width'] = "100%"
  bottom.style['pointer-events'] = "none"
  bottom.style.height = "1px"
  bottom.style.left = 0

  list = -> CONFIG.scrollcontainer || LIST

  LIST.appendChild bottom


  LIST.LENGTH = 0
  lastRenderPos = -1

  LIST.reload = (config) ->
    CONFIG = {
      ...CONFIG,
      ...config
    }
    lastRenderPos = -1
    list().scrollTop = 0
    LIST.LENGTH = await CONFIG.getlength()
    bottom.style.top = (CONFIG.marginTop+CONFIG.marginBottom+LIST.LENGTH*CONFIG.elementheight-1)+"px"
    rendered = rendered.filter (e) ->
      e.e.parentNode.removeChild e.e
      false
    LIST.render()

  LIST.render = ->
    scrollTop = list().scrollTop - CONFIG.marginTop
    start = Math.max scrollTop - CONFIG.buffer, 0

    i = Math.floor(start/CONFIG.elementheight)
    return if lastRenderPos == i
    lastRenderPos = i

    end = Math.min start + list().getBoundingClientRect().height + CONFIG.buffer*2, CONFIG.marginTop+LIST.LENGTH*CONFIG.elementheight

    i1 = Math.floor(start/CONFIG.elementheight)
    i2 = Math.min Math.ceil(end/CONFIG.elementheight), LIST.LENGTH-1

    makeRow = (i) ->
      div = rendered.find (e) -> e.i == i

      if !div
        div = document.createElement "div"
        div.style.position = "absolute"
        div.style.left = 0
        div.style['min-width'] = "100%"
        div.style.height = CONFIG.elementheight + "px"
        div.style.top = i*CONFIG.elementheight + CONFIG.marginTop + "px"

        Promise.resolve(CONFIG.getelementfor(i)).then (elem) ->
          div.innerHTML = ""
          div.appendChild elem

        LIST.appendChild div
        rendered.push {e:div,i:i}

    while i <= i2
      makeRow i
      i++;

    rendered = rendered.filter (e) ->
      keep = i2 >= e.i >= i1
      e.e.parentNode.removeChild e.e if !keep
      keep

  LIST.reload(config)

  scrollistener = ->
    LIST.render() if LIST.parentNode

  list().addEventListener "scroll", scrollistener

  LIST.destroy = ->
    list().removeEventListener "scroll", scrollistener
    rendered = rendered.filter (e) ->
      e.e.parentNode.removeChild e.e
      false

  LIST
}*/

(<any>Number.prototype).map = function (in_min: number, in_max: number, out_min: number, out_max: number): number {
    return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
};

export const EasingFunctions = {
    linear: function (t: number): number { return t; },
    easeInQuad: function (t: number): number { return t * t; },
    easeOutQuad: function (t: number): number { return t * (2 - t); },
    easeInOutQuad: function (t: number): number { return t < .5 ? 2 * t * t : -1 + (4 - (2 * t)) * t; },
    easeInCubic: function (t: number): number { return t * t * t },
    easeOutCubic: function (t: number): number { return --t * t * t + 1; },
    easeInOutCubic: function (t: number): number { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1; },
    easeInQuart: function (t: number): number { return t * t * t * t; },
    easeOutQuart: function (t: number): number { return 1 - (--t * t * t * t); },
    easeInOutQuart: function (t: number): number { return t < .5 ? 8 * t * t * t * t : 1 - (8 * --t * t * t * t); },
    easeInQuint: function (t: number): number { return t * t * t * t * t },
    easeOutQuint: function (t: number): number { return 1 + --t * t * t * t * t; },
    easeInOutQuint: function (t: number): number { return t < .5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t }
};
var tasks = {}, overlay = null, visible = false, t = null;
export function loading (id: string, loading: boolean) {
    if (!overlay && !visible) {
        var o = document.querySelector("#evb-loading-overlay")
        if (o) overlay = o;
    }
    if (loading) {
        tasks[id] = true;
        clearTimeout(t);
        if (!overlay) {
            overlay = document.createElement("div");
            overlay.id = "evb-loading-overlay";
            overlay.setAttribute("protected", "true");
            overlay.classList.add("fade-in");
            var roller = document.createElement("div");
            roller.className = "lds-roller";
            roller.appendChild(document.createElement("div"));
            roller.appendChild(document.createElement("div"));
            roller.appendChild(document.createElement("div"));
            roller.appendChild(document.createElement("div"));
            roller.appendChild(document.createElement("div"));
            roller.appendChild(document.createElement("div"));
            roller.appendChild(document.createElement("div"));
            roller.appendChild(document.createElement("div"));
            overlay.appendChild(roller);
            document.body.appendChild(overlay)
            visible = true
        }
        if (!visible) {
            overlay.classList.remove("fade-out")
            document.body.appendChild(overlay)
        }
        visible = true;
    } else {
        delete tasks[id];
        if (visible && overlay && Object.keys(tasks).length == 0)
            t = setTimeout(function () {
                overlay.classList.add("fade-out")
                t = setTimeout(function () {
                    try{document.body.removeChild(overlay)}catch(e){}
                    visible = false;
                }, 300)
            }, 100)
    }
}

window.addEventListener("load", function () {
    if (!(<any>window).appname) (<any>window).appname = "";

    if ((<any>window).appname && window.navigator && !(<any>window.navigator).standalone && Utils.isMobile.any()) {
        var create = function (typ?: string, clas?: string, text?: string, addto?: Element): Element {
            var o = document.createElement(typ || "div");
            if (clas) o.className = clas;
            if (text) o.innerText = text;
            if (addto) addto.appendChild(o);
            return o;
        }
        var overlay = create("div", "pwa-install-overlay fade-in", "", document.body);
        var center = create("div", "pwa-center-container", "", overlay);
        create("div", "before", "~", center)
        var popup = create("div", "pwa-install-popup" + (Utils.isMobile.iOS() || Utils.isMobile.iPadPro() ? "" : " android"), "", center);
        var appicon = create("div", "pwa-app-icon", "", popup);
        create("div", "img", "", appicon);
        create("div", "border", "", appicon);
        create("div", "pwa-app-name", "Install " + (<any>window).appname, popup);
        create("div", "pwa-message", "Install this application to your homescreen for quick and easy access when you're on the go!", popup);
        var footer = create("div", "pwa-footer", "", popup);
        create("span", "", "Just tap", footer);
        (<any>create("ion-icon", "ios", "", footer)).name = "share-outline";
        (<any>create("ion-icon", "android", "", footer)).name = "menu";
        create("span", "", "then 'Add to Home Screen'", footer);
        var close = create("ion-icon", "close", "", popup);
        (<any>close).name = "close";
        (<any>close).onclick = function () {
            overlay.classList.add("fade-out");
            setTimeout(function () {
                window.dispatchEvent(new CustomEvent('init', { detail: null }));
            }, 500);
        };
        create("div", "after", "~", center);
    } else {
        window.dispatchEvent(new CustomEvent('init', { detail: null }));
    }
});


(function(arr){
    arr.forEach(function(item){
        if(item.hasOwnProperty('remove')) return;
        Object.defineProperty(item, 'remove', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function () {
                if(this.parentNode == null) return;
                this.parentNode.removeChild(this);
            }
        })
    });
}) ([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
(function(arr){
    arr.forEach(function(item){
        if(item.hasOwnProperty('create')) return;
        Object.defineProperty(item, 'create', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function (outerPug, innerText) {
                this.appendChild(create(outerPug, innerText));
            }
        })
    });
}) ([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
