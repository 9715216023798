import { StateView, View, UserFooter } from "./support";
import { ClientState, ClientView } from "../../../node/src/shared/client";
import { create, loading } from "../api";
import { Locals } from "../../../node/src/shared/locals";
import { kofiwidget2 } from "../kofiwidget2";

declare var locals:Locals;

export class ServersList implements StateView {
    type = ClientView.guilds
    footer:View
    collection:HTMLElement
    socketio:SocketIOClient.Socket
    constructor(state:ClientState, socketio:SocketIOClient.Socket){
        var body = document.body;
        this.socketio = socketio;
        // Render the Header
        var header = body.appendChild(create("header.content-wrapper"));
        var headerCenter = header.appendChild(create(".content-center"));
        var headerCenterHoriz = headerCenter.appendChild(create(".horiz"));
        headerCenterHoriz.appendChild(create(".logo"));
        headerCenterHoriz.appendChild(create("h2", locals.short_site_name));
        headerCenter.appendChild(create(".subtle", locals.version));

        // Render the Collection
        var page = body.appendChild(create("page.content-wrapper"));
        page.style.setProperty('height','auto');
        page.style.setProperty('min-height','calc(100vh - 160px)');
        this.collection = page.appendChild(create(".float-center"));
        this.setState(state);

        // Render the Footer
        this.footer = new UserFooter();
    }
    setState(state:ClientState):void{
        this.collection.innerHTML = "";
        state.guilds.forEach(guild=>{
            var _guild = this.collection.appendChild(create(".server-card"));
            var icon = _guild.appendChild(create(".server-icon"));
            icon.style.setProperty("background-image", "url("+guild.iconURL+")");
            var name = _guild.appendChild(create(".server-name"));
            name.appendChild(create('span[style=padding:0 2px;box-shadow:0 0 10px var(--foreground-color)]',guild.public_title));

            icon.addEventListener("click", ()=>{
                loading("rendering",true);
                this.socketio.emit("state", {
                    view: ClientView.topics,
                    guild: guild.id
                });
            });
        });
        var center = this.collection.appendChild(create(".center-in-flex"));
        center.appendChild(create("span.center[style=background-color:var(--foreground-color);padding:0 2px;margin:1rem 0;box-shadow:0 0 10px var(--background-color)]", "Don't see the server you're looking for?"));
        var add2server = center.appendChild(create("button.center", "Add Usagi to your server"));
        add2server.addEventListener("click",function(){
            window.open(locals.bot_link,'_blank');
        });
        center.appendChild(create("span.center[style=background-color:var(--foreground-color);padding:0 2px;margin:1rem 0;box-shadow:0 0 10px var(--background-color)]", "Feeling Generous?"));
        center.innerHTML+=kofiwidget2.getHTML();
    }
    onDestroy(){
        this.footer.onDestroy();
    }
}