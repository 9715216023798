export class Permissions {
    /**
     * The stored JSON permissions mask
     *
     * @type {Permissions.Raw}
     * @memberof Permissions
     */
    raw:Permissions.Raw
    constructor(initalmask?:Permissions.Raw){
        this.raw = initalmask || {allow:0,disallow:0}
        this.raw.disallow = (this.raw.disallow | this.raw.allow) ^ this.raw.allow
    }
    val(permission:Permissions.Bit, raw:boolean = false):-1|0|1 {
        if(this.raw.allow & Permissions.Bit.MANAGE_GUILD && !raw) return 1;
        return this.raw.disallow & permission ? -1 : this.raw.allow & permission ? 1 : 0
    }
    /**
     * Gets the permission state in the permission mask
     *
     * @param {Permissions.Bit} permission - Permission bit to check
     * @param {(-1|0|1)} allowed - -1 = disallowed, 0 = inherit, 1 = allowed
     * @returns {boolean}
     * @memberof Permission
     */
    get(permission:Permissions.Bit,allowed:-1|0|1):boolean{
        if(allowed == -1){
            if(this.raw.allow & Permissions.Bit.MANAGE_GUILD) return false;
            return this.raw.disallow & permission ? true : false
        }else if(allowed == 0){
            if(this.raw.allow & Permissions.Bit.MANAGE_GUILD) return false;
            return this.raw.allow & permission ? false : this.raw.disallow & permission ? false : true
        }else if(allowed == 1){
            if(this.raw.allow & Permissions.Bit.MANAGE_GUILD) return true;
            return this.raw.allow & permission ? true : false
        }
        return false;
    }
    /**
     * Sets the permission state in the permission mask
     *
     * @param {PermissionBit} permission
     * @param {(-1|0|1)} allowed - -1 = disallow, 0 = inherit, 1 = allow
     * @memberof Permission
     */
    set(permission:Permissions.Bit,allowed:-1|0|1):void{
        if(allowed == -1){
            this.raw.allow = (this.raw.allow | permission) ^ permission
            this.raw.disallow = this.raw.disallow | permission
        }else if(allowed == 0){
            this.raw.allow = (this.raw.allow | permission) ^ permission
            this.raw.disallow = (this.raw.disallow | permission) ^ permission
        }else if(allowed == 1){
            this.raw.disallow = (this.raw.disallow | permission) ^ permission
            this.raw.allow = this.raw.allow | permission
        }
    }
    static stack(...masks:(Permissions.Raw[])):Permissions.Raw {
        var mask:Permissions.Raw = {allow:0,disallow:0}
        masks.forEach(msk => {
            mask.allow = mask.allow | msk.allow
            mask.disallow = (mask.disallow | msk.disallow | mask.allow) ^ mask.allow
        });
        return mask
    }
    toJSON():any {
        return this.raw
    }
}
export namespace Permissions {
    export interface Raw {
        allow: number
        disallow: number
    }
    /**
     * Permissions Bitmask
     *
     * @enum {number}
     */
    export enum Bit {
        /**
         * View Suggestions & Comments in the Topic
         * Setting this to disallow on the base level is equivlant to blacklisting
         */
        VIEW = 1,
        /**
         * Create Suggestions in the Topic
         */
        SUGGEST = 2,
        /**
         * Create Comments on Suggestions in the Topic
         */
        COMMENT = 4,
        /**
         * Vote on Comments and Suggestions in the Topic
         */
        VOTE = 6,
        /**
         * Delete Comments on Suggestions in the Topic
         */
        DELETE_COMMENTS = 8,
        /**
         * Delete Suggestions in the Topic
         */
        DELETE_SUGGESTIONS = 16,
        /**
         * Edit Comment Contents for Comments on Suggestions in the Topic
         */
        EDIT_COMMENT_CONTENTS = 32,
        /**
         * Edit Suggestion Titles for Suggestions in the Topic
         */
        EDIT_SUGGESTION_TITLES = 64,
        /**
         * Edit Suggestion Contents for Suggestions in the Topic
         */
        EDIT_SUGGESTION_CONTENTS = 128,
        /**
         * Edit Suggestion Tags for Suggestions in the Topic
         * (Intended for Flagging by Curators and Mods)
         */
        EDIT_SUGGESTION_TAGS = 256,
        /**
         * Change Suggestion Topic for Suggestions in the Topic
         * This permission is required for any action concerning tags
         */
        MOVE_SUGGESTIONS = 512,
        /**
         * Create & Delete Topics
         */
        MANAGE_TOPICS = 1024, // Not Used right Now
        /**
         * All Permissions are allowed and access to settings
         */
        MANAGE_GUILD = 2048
    }
}