import { ClientState, ClientView } from "../../../node/src/shared/client"
import { create } from "../api";
import { Locals } from "../../../node/src/shared/locals";

declare var locals:Locals;

export abstract class View {
    onDestroy() {}
}
export abstract class StateView extends View {
    type:ClientView = ClientView.unauthorized
    constructor (state:ClientState, socketio:SocketIOClient.Socket) {super()}
    setState(state:ClientState) {}
    onDestroy() {}
}

export class UserFooter implements View {
    constructor(){
        var body = document.body;
        var header = body.appendChild(create("header.content-wrapper"));
        var headerCenter = header.appendChild(create(".content-center"));
        var headerCenterHoriz = headerCenter.appendChild(create(".horiz"));
        headerCenterHoriz.appendChild(create(`.icon[style=background-image:url(https://cdn.discordapp.com/avatars/${locals.discord.id}/${locals.discord.avatar}.${locals.discord.avatar.indexOf("a_")==0?"gif":"png"}?size=128)]`));
        headerCenterHoriz.appendChild(create(".subtle", locals.discord.username+"#"+locals.discord.discriminator));
        var logout = headerCenter.appendChild(create(".subtle[style=text-decoration:underline;cursor:pointer]", "Logout"));
        logout.addEventListener("click", function(){
            location.href = "/logout?path="+encodeURIComponent(location.pathname);
        });
    }
    onDestroy(){}
}