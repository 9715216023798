//#region imports
import 'regenerator-runtime/runtime';
import socketio from "socket.io-client";
import { Theme, Guild, merge, themeDefault } from "../../node/src/shared/shared";
import { ClientState, ClientView, clientStateRequestFrom, ClientStateRequest, ClientMessageType, urlPathFromClientState } from "../../node/src/shared/client";
import { Locals } from "../../node/src/shared/locals";
import { Utils, create, loading } from "./api";
import { kofiwidget2 } from "./kofiwidget2";
import { Popup } from "./popup";
import { PublicPage } from './views/PublicPage';
import { ServersList } from './views/ServersList';
import { StateView } from './views/support';
import { TopicView } from './views/TopicView';
import { SettingsView } from './views/SettingsView';
import { SuggestionView } from './views/SuggestionView';
//#endregion

//#region Globals Setup
declare var locals:Locals;
declare var SmoothScroll:any;
kofiwidget2.init('Support Me on Ko-fi', 'var(--emphasis-color)', 'Y8Y210JNT');
//#endregion

//#region URL v1 and v1.2 upgrade method to v2.0, unfortunately suggestion is not preserved because of the new ID system
if(location.search.length >= 17){
    location.href = "/"+location.search.replace("?","").split("/")[0]
}else if(location.hash.length >= 17){
    location.href = "/"+location.hash.replace("#","").split("/")[0]
}
//#endregion
var wsurl = location.origin+"/?key="+locals.sessionkey+"&path="+encodeURIComponent(location.pathname)+"&EIO=4&transport=websocket";
if(location.protocol == "https:") wsurl = wsurl.replace("https:","wss:");
else wsurl = wsurl.replace("http:","ws:");
console.log("Connecting To:",wsurl);
var socket = socketio(wsurl);
var smoothscroll = new SmoothScroll('a[href*="#"]');
var currentView:StateView = null;

const REFRESH_INTERVAL = 1000;
var refreshtimeout:NodeJS.Timeout = null;
var gotnewat = 0;
socket.on("state",function(state:ClientState, err:string){
    console.log(state,err);
    clearTimeout(refreshtimeout);
    gotnewat = Date.now();
    if(err) location.href = "/logout?path="+encodeURIComponent(location.pathname);
    else {
        render(state);
        refreshtimeout = setTimeout(function(){
            socket.emit("refresh");
        },REFRESH_INTERVAL);
    }
});

//var topicsScrollTop = 0;

async function render(state?:ClientState) {
    var body = document.body;
    // Update the theme currectly
    if(state && state.guild){
        var guild:Guild = merge(state.guild, state.guilds.find(g=>g.id==state.guild.id));
        setTheme(merge(guild.theme,themeDefault), guild.splashURL||"/images/usagi.jpg", guild.iconURL||"/images/usagi.gif");
        updateURLPath(urlPathFromClientState(state), guild.public_title)
    }else{
        if(state) setTheme(themeDefault, "/images/usagi.jpg", "/images/usagi.gif");
        else setTheme(locals.theme, locals.splashURL, locals.iconURL);
        if(state) updateURLPath("/", "Usagi Suggestions")
    }
    
    if(state && currentView && state.view == currentView.type){
        // If the state and currentView are set AND the view type is the same just update
        currentView.setState(state);
    }else{
        // No match, render fresh!
        if(currentView) currentView.onDestroy();
        Utils.reset();
        currentView = null;
        body.appendChild(create(".bg"));
        if(locals.discord){
            if(state){
                switch(state.view){
                    case ClientView.unauthorized:
                        location.href = "/logout?path="+encodeURIComponent(location.pathname);
                        break;
                    case ClientView.guilds:
                        currentView = new ServersList(state, socket);
                        window.scrollTo(0,0);
                        break;
                    case ClientView.topics:
                        currentView = new TopicView(state, socket);
                        window.scrollTo(0,0);
                        break;
                    case ClientView.suggestion:
                        currentView = new SuggestionView(state, socket);
                        window.scrollTo(0,0);
                        break;
                    case ClientView.settings:
                        currentView = new SettingsView(state, socket);
                        window.scrollTo(0,0);
                }
            }else{
                //Create and Send Inital State Request
                console.log("Input:",location.pathname);
                var req:ClientStateRequest = clientStateRequestFrom(location.pathname);
                console.log("Requesting:",req);
                socket.emit("state",req);
                return loading("rendering", true);
            }
        }else{
            currentView = new PublicPage(null);
        }
    }

    // Add popup message if provided in the state
    if(state && state.message){
        var popup = new Popup(body);
        popup.setTitle(
            state.message.type == ClientMessageType.INFO ? "- INFO -" :
            state.message.type == ClientMessageType.WARNING ? "- WARNING -" :
            state.message.type == ClientMessageType.ERROR ? "- ERROR -" : " - UNKNOWN -"
        );
        popup.setBody(state.message.text);
        popup.addButton("Close", popup.remove);
    }

    loading("rendering", false);
}
export function setTheme(theme:Theme, splashURL:string, iconURL:string){
    var body = document.querySelector("html");
    var s = getComputedStyle(body);
    if(s.getPropertyValue("--background-color") != theme.backgroundColor) body.style.setProperty("--background-color", theme.backgroundColor);
    if(s.getPropertyValue("--foreground-color") != theme.foregroundColor) body.style.setProperty("--foreground-color", theme.foregroundColor);
    if(s.getPropertyValue("--color") != theme.textColor) body.style.setProperty("--color", theme.textColor);
    if(s.getPropertyValue("--emphasis-color") != theme.emphasisColor) body.style.setProperty("--emphasis-color", theme.emphasisColor);
    if(s.getPropertyValue("--border-color") != theme.borderColor) body.style.setProperty("--border-color", theme.borderColor);
    if(s.getPropertyValue("--splash") != `url(${splashURL})`) body.style.setProperty("--splash", `url(${splashURL})`);
    if(s.getPropertyValue("--icon") != `url(${iconURL})`) body.style.setProperty("--icon", `url(${iconURL})`);
}

//#region Init
window.addEventListener("init",function(){
    loading("rendering", true);
    render();
});
function updateURLPath(path:string, title:string){
    if(location.pathname == path) return;
    if (history.pushState) {
        window.history.pushState(Math.random(), title, path);
    } else {
        document.location.href = path;
    }
}
// Handle back button and re-render
window.onpopstate = function (event) {
    console.log("POPSTATE", location.pathname);
    loading("rendering", true);
    render();
}
//#endregion
