import { Permissions } from "./permissions"

/**
 * The Plain JSON format for Guilds
 *
 * @interface Guild
 */
export interface Guild {
    /**
     * MongoDB HEX ID String
     *
     * @type {string}
     * @memberof Guild
     */
    _id: string,
    /**
     * Discord Guild ID
     *
     * @type {string}
     * @memberof Guild
     */
    id: string
    /**
     * Server icon URL
     * "" = inherit from discord, ".*" = override
     * @type {string}
     * @memberof Guild
     */
    iconURL: string
    /**
     * Server splash URL
     * "" = inherit from discord, ".*" = override
     * @type {string}
     * @memberof Guild
     */
    splashURL: string

    /**
     * The title displayed on the public sign-in page
     *
     * @type {string}
     * @memberof Guild
     */
    public_title: string
    /**
     * The subtitle dispayed on the public sign-in page
     *
     * @type {string}
     * @memberof Guild
     */
    public_subtitle: string
    /**
     * The description dispayed on the public sign-in page under the "What is?" section
     *
     * @type {string}
     * @memberof Guild
     */
    public_description: string

    /**
     * The Title displayed at the top of the topics view
     *
     * @type {string}
     * @memberof Guild
     */
    private_title: string
    /**
     * The Subtitle displayed at the top of the topics view
     *
     * @type {string}
     * @memberof Guild
     */
    private_subtitle: string
    /**
     * The Description displayed at the top of the topics view
     *
     * @type {string}
     * @memberof Guild
     */
    private_description: string

    /**
     * The maximum title length for a suggestion
     *
     * @type {number}
     * @memberof Guild
     */
    max_suggestion_title_length: number
    /**
     * The maximum description length for a suggestion
     *
     * @type {number}
     * @memberof Guild
     */
    max_suggestion_description_length: number
    /**
     * The minimum title length for a suggestion
     *
     * @type {number}
     * @memberof Guild
     */
    min_suggestion_title_length: number
    /**
     * The minimum description length for a suggestion
     *
     * @type {number}
     * @memberof Guild
     */
    min_suggestion_description_length: number

    /**
     * The channel to post suggestions in by default
     * "" = do not post by default, "\d*" = post in specified channel
     * @type {string}
     * @memberof Guild
     */
    default_discord_channel: string

    /**
     * The overall color theme for this server
     *
     * @type {Theme}
     * @memberof Guild
     */
    theme: Theme

    /**
     * The rough suggestion count for numbering suggestions
     *
     * @type {number}
     * @memberof Guild
     */
    rough_suggestion_count: number
}
/**
 * The Plain JSON format for Roles
 *
 * @export
 * @interface Role
 */
export interface Role {
    _id: string,
    /**
     * Parent Discord Guild ID
     *
     * @type {string}
     * @memberof Suggestion
     */
    guild: string,
    /**
     * The role name
     *
     * @type {string}
     * @memberof Role
     */
    name?: string
    /**
     * Discord Role ID
     *
     * @type {string}
     * @memberof Guild
     */
    id: string
    /**
     * Default permissions for this role
     * Permissions are stacked in the order loaded from discord
     *
     * @type {Permissions.Raw}
     * @memberof Topic
     */
    permissions: Permissions.Raw
}
export enum TopicType {
    TOPIC = 0,
    CATEGORY = 1
}
/**
 * The Plain JSON format for Topics
 *
 * @interface Topic
 */
export interface Topic {
    _id: string
    /**
     * Parent Discord Guild ID
     *
     * @type {string}
     * @memberof Suggestion
     */
    guild: string
    /**
     * Topic Name
     *
     * @type {string}
     * @memberof Topic
     */
    type: TopicType
    /**
     * Parent Topic id
     *
     * @type {string}
     * @memberof Topic
     */
    parent?: string
    /**
     * Topic Name
     *
     * @type {string}
     * @memberof Topic
     */
    name: string
    /**
     * Permission Overrides for each role
     * The key:string is the Discord Role ID
     *
     * @type {{ [key:string]:Permissions.Raw }}
     * @memberof Topic
     */
    permission_overrides: {[key:string]:Permissions.Raw}
    /**
     * The channel to post this topic's suggestions in
     * "" = inherit, "null" = do not post, "\d*" = post in specified channel
     * @type {number}
     * @memberof Topic
     */
    discord_channel: string
    /**
     * An arbitrary number for declaring the order of Topics
     *
     * @type {number}
     * @memberof Category
     */
    order: number

    created: Date
    updated: Date
}
/**
 * The Plain JSON format for Suggestions
 *
 * @interface Suggestion
 */
export interface Suggestion {
    _id: string
    /**
     * Parent Guild ID
     *
     * @type {string}
     * @memberof Suggestion
     */
    guild: string
    /**
     * Parent Topic ID
     *
     * @type {string}
     * @memberof Suggestion
     */
    topic: string
    /**
     * Suggestion Title
     *
     * @type {string}
     * @memberof Suggestion
     */
    title: string
    /**
     * Suggestion Description
     *
     * @type {string}
     * @memberof Suggestion
     */
    description: string
    /**
     * Extra tags that can be added to a suggestion
     *
     * @type {string[]}
     * @memberof Suggestion
     */
    tags: string[]
    /**
     * Author info for searching
     *
     * @type {AuthorInfo}
     * @memberof Suggestion
     */
    author: AuthorInfo
    /**
     * User Votes
     *
     * @type {Votes}
     * @memberof Suggestion
     */
    votes: Votes
    /**
     * Array of SOUNDEX ids
     *
     * @type {string[]}
     * @memberof Suggestion
     */
    soundex: string[]
    /**
     * Ordinal number for suggestion numbering
     * No real purpose other than display
     *
     * @type {number}
     * @memberof Suggestion
     */
    number: number
    /**
     * Reference to the Generated Message in Discord
     *
     * @type {MessageRef}
     * @memberof Suggestion
     */
    message?: MessageRef

    soundex_score?: number
    cachedStats: {
        votes: number
        upvotes: number
        downvotes: number
        positivity: number
        comments: number
    }

    created: Date
    updated: Date
}
export const suggestionDefault:Suggestion = {
    _id:"",
    guild:"",
    topic:"",
    title:"",
    description:"",
    tags:[],
    author:{id:"",tag:"",nickname:"",icon:""},
    votes:{up:[],down:[]},
    soundex:[],
    number:0,
    message:undefined,
    cachedStats:{
        votes: 0,
        upvotes: 0,
        downvotes: 0,
        positivity: 1,
        comments: 0
    },
    created: new Date(),
    updated: new Date()
}
export interface MessageRef {
    /**
     * Discord Channel ID
     *
     * @type {string}
     * @memberof MessageRef
     */
    channel: string
    /**
     * Discord Message ID
     *
     * @type {string}
     * @memberof MessageRef
     */
    message: string
}
export interface Comment {
    _id: string
    /**
     * Parent Guild ID
     *
     * @type {string}
     * @memberof Suggestion
     */
    guild: string
    /**
     * Parent Suggestion ID
     *
     * @type {string}
     * @memberof Suggestion
     */
    suggestion: string
    /**
     * The Comment Contents
     *
     * @type {string}
     * @memberof Comment
     */
    description:string,
    /**
     * Author info for searching
     *
     * @type {AuthorInfo}
     * @memberof Comment
     */
    author: AuthorInfo
    votes: {
        /**
         * ids of all users who upvoted
         *
         * @type {string[]}
         */
        up: string[]
        /**
         * ids of all users who downvoted
         *
         * @type {string[]}
         */
        down: string[]
    }

    created: Date
    updated: Date
}
export interface AuthorInfo {
    id: string
    tag: string
    nickname: string
    icon: string
}
export interface Theme {
    emphasisColor: string,
    textColor: string,
    foregroundColor: string,
    backgroundColor: string,
    borderColor: string
}
export const themeDefault:Theme = {
    emphasisColor: '#2d7ff9',
    textColor: '#000000',
    foregroundColor: "#ffffff",
    backgroundColor: '#23003D',
    borderColor: '#aaaaaa'
}
/**
 * The plain JSON format for Votes
 *
 * @export
 * @interface Votes
 */
export interface Votes {
    /**
     * ids of all users who upvoted
     *
     * @type {string[]}
     */
    up: string[]
    /**
     * ids of all users who downvoted
     *
     * @type {string[]}
     */
    down: string[]
}

export interface APIResponse {
    status: boolean,
    message: string,
    data: any
}

//#region Brainstorming / Other
// New Proposed Format
/*class Defaultable {constructor(json: any){}}
class NewGuild extends Defaultable implements NewGuildJSON {
    constructor(json: NewGuildJSON){
        super(json);
        Object.keys(NewGuildDefault).forEach(key=>{
            this[key] = json[key] || NewGuildDefault[key];
        })
    }}
interface NewGuildJSON {
    
}
const NewGuildDefault = {

}*/
//#endregion

export function makeProjection(obj:any, val:1|0):any{
    var out:any = {};
    for (const key in obj) {
        out[key] = val;
    }
    return out;
}
export function merge<T>(_a:T,_b:T):T{
    var a:any = _a||{}, b:any = _b||{}, c:any = {...a,...b};
    Object.keys(a).forEach(k=>{
        c[k] = a[k] || b[k];
    });
    Object.keys(b).forEach(k=>{
        c[k] = a[k] || b[k];
    });
    return c as T;
}

export function compilePermissionsResults(myroles:string[], roles:Role[], topic:Topic, topicparent?:Topic):Permissions{
    var pstack:Permissions.Raw[] = [];
    roles.forEach(r=>{
        // If we have the role add it to the stack
        if(myroles.indexOf(r.id) > -1){
            pstack.push(r.permissions);
        }
    });
    if(topicparent) roles.forEach(r=>{
        // If we have the role add it to the stack
        if(myroles.indexOf(r.id) > -1){
            if(topicparent.permission_overrides[r.id]){
                pstack.push(topicparent.permission_overrides[r.id]);
            }
        }
    });
    if(topic) roles.forEach(r=>{
        // If we have the role add it to the stack
        if(myroles.indexOf(r.id) > -1){
            if(topic.permission_overrides[r.id]){
                pstack.push(topic.permission_overrides[r.id]);
            }
        }
    });
    return new Permissions(Permissions.stack.apply(null,pstack))
}