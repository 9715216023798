import { StateView, View, UserFooter } from "./support";
import { ClientState, ClientView, ClientStateRequest, ClientActions, ChannelRef } from "../../../node/src/shared/client";
import { create, loading } from "../api";
import { Locals } from "../../../node/src/shared/locals";
import { merge, TopicType, Guild, themeDefault, Topic, Role } from "../../../node/src/shared/shared";
import { Permissions } from "../../../node/src/shared/permissions";
import { setTheme } from "..";
import { Popup } from "../popup";

declare var locals:Locals;

function makePermissionRow(name:string, description:string, state:-1|0|1, onchange:(state:-1|0|1)=>void):HTMLElement {
    //TODO: Permissions Row
    var c = create(".permission-row");
    var split = c.appendChild(create(".permissions-split"));
    split.appendChild(create("h4", name));
    var thing = split.appendChild(create(".selector-thing"));
    var no = thing.appendChild(create("ion-icon.no[name=close-outline]"));
    var maybe = thing.appendChild(create("ion-icon.maybe[name=remove-outline]"));
    var yes = thing.appendChild(create("ion-icon.yes[name=checkmark-outline]"));
    no.addEventListener("click",()=>{
        setstate(-1);
        onchange(-1);
    });
    maybe.addEventListener("click",()=>{
        setstate(0);
        onchange(0);
    });
    yes.addEventListener("click",()=>{
        setstate(1);
        onchange(1);
    });

    function setstate(state:-1|0|1){
        no.classList[state==-1?"add":"remove"]("selected");
        maybe.classList[state==0?"add":"remove"]("selected");
        yes.classList[state==1?"add":"remove"]("selected");
    }

    setstate(state);

    c.appendChild(create("span", description));
    return c;
}

function makeInput(parent:HTMLElement, title:string, nopadd?:boolean):HTMLInputElement {
    var c = parent.appendChild(create(".floating-titled-input"));
    if(nopadd){c.style["margin-left"]="0";
    c.style["margin-right"]="0";}
    var i = c.appendChild(create("input")) as HTMLInputElement;
    i.placeholder = title
    c.setAttribute("title",title);
    return i;
}
function makeTextArea(parent:HTMLElement, title:string):HTMLTextAreaElement {
    var c = parent.appendChild(create(".floating-titled-input"));
    var i = c.appendChild(create("textarea[rows=1]")) as HTMLTextAreaElement;
    textFieldAutoSize.call(i);
    i.addEventListener("input",textFieldAutoSize,false);
    i.placeholder = title
    c.setAttribute("title",title);
    return i;
}
export function textFieldAutoSize(this:HTMLTextAreaElement){
    if(document.activeElement == this) this.style.height = 'auto';
    if(this.scrollHeight == 36) this.style.height = this.scrollHeight-16+'px';
    else this.style.height = this.scrollHeight+'px';
}
function makeRoleRow(onchange:(perms:Permissions)=>void, permissions:Permissions.Raw, name:string, hidetoplevelperms:boolean):HTMLElement {
    // Role Name - Permissions Number - Edit Button
    var c = create(".settings-row");
    c.appendChild(create("span", name));
    var display = c.appendChild(create("span", permissions.allow+"-"+permissions.disallow));
    c.addEventListener("click",()=>{
        var myperms:Permissions = new Permissions(permissions);
        new PermissionsPopup(myperms, name, hidetoplevelperms, document.body, function(perms:Permissions){
            myperms = perms;
        }, ()=>{
            display.innerText = myperms.raw.allow+"-"+myperms.raw.disallow;
            onchange(myperms);
        });
    });
    return c;
}
function makeTopicRow(view:SettingsView, topic:Topic):HTMLElement {
    // Topic Name - Move Up - Move Down
    var c = create(".settings-row");
    c.appendChild(create("span", topic.name));
    var row = c.appendChild(create("div[style=display:flex]"));
    // If not top
    var up = row.appendChild(create(`ion-icon[name=chevron-up-outline]`));
    if(view._topics[0]._id == topic._id) up.style.opacity = '0';
    // If not botton
    var down = row.appendChild(create(`ion-icon[name=chevron-down-outline]`));
    if(view._topics[view._topics.length-1]._id == topic._id) down.style.opacity = '0';
    var edit = row.appendChild(create(`ion-icon[name=create-outline]`));
    up.addEventListener("click",()=>{
        if(view._topics[0]._id == topic._id) return;
        var i = view._topics.findIndex(t=>t._id==topic._id);
        // Get the 2 above
        var oneUp = view._topics[i-1];
        var twoUp = view._topics[i-2];
        if(twoUp) topic.order = (oneUp.order+twoUp.order)/2
        else topic.order = oneUp.order - 100000
        var req:ClientStateRequest = {
            view: ClientView.settings,
            guild: view.guild.id,
            action: {
                action: ClientActions.EDIT_TOPIC,
                data: topic
            }
        }
        loading("rendering",true);
        view.socketio.emit("state",req);
    });
    down.addEventListener("click",()=>{
        if(view._topics[view._topics.length-1]._id == topic._id) return;
        var i = view._topics.findIndex(t=>t._id==topic._id);
        // Get the 2 below
        var oneDown = view._topics[i+1];
        var twoDown = view._topics[i+2];
        console.log(oneDown,twoDown);
        if(twoDown) topic.order = (oneDown.order+twoDown.order)/2;
        else topic.order = Date.now();
        var req:ClientStateRequest = {
            view: ClientView.settings,
            guild: view.guild.id,
            action: {
                action: ClientActions.EDIT_TOPIC,
                data: topic
            }
        }
        loading("rendering",true);
        view.socketio.emit("state",req);
    });
    edit.addEventListener("click",()=>{
        new TopicPopup(view,topic,document.body);
    })
    return c;
}
function listenForAndApplyChange(container:SettingsView, elem:HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement, prop:string, prop2:string, isNumber:boolean){
    elem.addEventListener("keydown",function(e:KeyboardEvent){
        if(e.keyCode == 13) elem.blur();
    });
    elem.addEventListener("change",function(){
        console.log("Changed!!");
        var req:ClientStateRequest = {
            view: ClientView.settings,
            guild: container.guild.id,
            action: {
                action: ClientActions.EDIT_GUILD,
                data: {
                    prop1: prop,
                    prop2: prop2,
                    value: isNumber?Number(elem.value)||0:elem.value
                }
            }
        }
        container.socketio.emit("state",req);
    });
}
// TODO: Permissions Popup
// this is just a list of all the permissions and what they do with an options to disable guild specific permissions
class PermissionsPopup extends Popup {
    constructor(perms:Permissions, title:string, hidetoplevelperms:boolean, parent:HTMLElement, onchange:(perms:Permissions)=>void, onclose:()=>void){
        super(parent);
        this.setTitle(title);

        //TODO Permissions View
        this.content.appendChild(makePermissionRow("VIEW","View Suggestions & Comments in the Topic\nSetting this to disallow on the base level is equivlant to blacklisting",perms.val(Permissions.Bit.VIEW,true),(state)=>{
            perms.set(Permissions.Bit.VIEW, state);
            onchange(perms);
        }));
        this.content.appendChild(makePermissionRow("SUGGEST","Create Suggestions",perms.val(Permissions.Bit.SUGGEST,true),(state)=>{
            perms.set(Permissions.Bit.SUGGEST, state);
            onchange(perms);
        }));
        this.content.appendChild(makePermissionRow("COMMENT","Create Comments",perms.val(Permissions.Bit.COMMENT,true),(state)=>{
            perms.set(Permissions.Bit.COMMENT, state);
            onchange(perms);
        }));
        this.content.appendChild(makePermissionRow("VOTE","Vote on Comments and Suggestions",perms.val(Permissions.Bit.VOTE,true),(state)=>{
            perms.set(Permissions.Bit.VOTE, state);
            onchange(perms);
        }));
        this.content.appendChild(makePermissionRow("DELETE_COMMENTS","Delete other user's comments",perms.val(Permissions.Bit.DELETE_COMMENTS,true),(state)=>{
            perms.set(Permissions.Bit.DELETE_COMMENTS, state);
            onchange(perms);
        }));
        this.content.appendChild(makePermissionRow("DELETE_SUGGESTIONS","Delete other user's suggestions",perms.val(Permissions.Bit.DELETE_SUGGESTIONS,true),(state)=>{
            perms.set(Permissions.Bit.DELETE_SUGGESTIONS, state);
            onchange(perms);
        }));
        this.content.appendChild(makePermissionRow("EDIT_COMMENT_CONTENTS","Edit other user's suggestions",perms.val(Permissions.Bit.EDIT_COMMENT_CONTENTS,true),(state)=>{
            perms.set(Permissions.Bit.EDIT_COMMENT_CONTENTS, state);
            onchange(perms);
        }));
        this.content.appendChild(makePermissionRow("EDIT_SUGGESTION_TITLES","Edit other user's suggestions' titles",perms.val(Permissions.Bit.EDIT_SUGGESTION_TITLES,true),(state)=>{
            perms.set(Permissions.Bit.EDIT_SUGGESTION_TITLES, state);
            onchange(perms);
        }));
        this.content.appendChild(makePermissionRow("EDIT_SUGGESTION_CONTENTS","Edit other user's suggestions' contents",perms.val(Permissions.Bit.EDIT_SUGGESTION_CONTENTS,true),(state)=>{
            perms.set(Permissions.Bit.EDIT_SUGGESTION_CONTENTS, state);
            onchange(perms);
        }));
        /*this.content.appendChild(makePermissionRow("EDIT_SUGGESTION_TAGS","Edit Suggestion Tags (Coming Soon)",perms.val(Permissions.Bit.EDIT_SUGGESTION_TAGS,true),(state)=>{
            perms.set(Permissions.Bit.EDIT_SUGGESTION_TAGS, state);
            onchange(perms);
        }));*/
        this.content.appendChild(makePermissionRow("MOVE_SUGGESTIONS","Edit other user's suggestions' topic",perms.val(Permissions.Bit.MOVE_SUGGESTIONS,true),(state)=>{
            perms.set(Permissions.Bit.MOVE_SUGGESTIONS, state);
            onchange(perms);
        }));
        /*if(!hidetoplevelperms)
        this.content.appendChild(makePermissionRow("MANAGE_TOPICS","",perms.val(Permissions.Bit.MANAGE_TOPICS,true),(state)=>{
            perms.set(Permissions.Bit.MANAGE_TOPICS, state);
            onchange(perms);
        }));*/ // NOT USED
        if(!hidetoplevelperms)
        this.content.appendChild(makePermissionRow("MANAGE_GUILD","Access to everything.",perms.val(Permissions.Bit.MANAGE_GUILD,true),(state)=>{
            perms.set(Permissions.Bit.MANAGE_GUILD, state);
            onchange(perms);
        }));

        this.addButton("Close",()=>{
            onclose();
            this.remove();
        });
    }
}
// Topic Popup
class TopicPopup extends Popup {
    constructor(view:SettingsView, topic:Topic, parent:HTMLElement){
        super(parent);

        this.setTitle("Topic Settings For: "+topic.name);

        // Roles View
        var elem = makeInput(this.content, "Topic Name");
        elem.value = topic.name;
        elem.addEventListener("input",()=>{
            topic.name = elem.value;
            this.setTitle("Topic Settings For: "+topic.name);
        });

        var _discord_channel = this.content.appendChild(create(".floating-titled-input"));
        _discord_channel.setAttribute("title", "Discord Channel");
        var _discord_channel_wrapper = _discord_channel.appendChild(create(".flatselect-wrapper"));
        var discord_channel = _discord_channel_wrapper.appendChild(create("select.flatselect")) as HTMLSelectElement;

        discord_channel.innerHTML = "";
        discord_channel.appendChild(create("option[value=]", "Inherit"));
        discord_channel.appendChild(create("option[value=null]", "None"));
        
        view._channels.forEach(channelref => {
            discord_channel.appendChild(create("option[value="+channelref.id+"]", channelref.name))
        });
        discord_channel.value = topic.discord_channel || "";

        discord_channel.addEventListener("change",()=>{
            topic.discord_channel = discord_channel.value || "";
        });

        this.content.appendChild(create("h4","Permission Overrides"))

        view._roles.forEach(role => {
            this.content.appendChild(makeRoleRow(function(perms:Permissions):void{
                topic.permission_overrides[role.id] = perms.raw;
            }, topic.permission_overrides[role.id] || {allow:0,disallow:0}, role.name, true));
        });

        if(topic._id){
            this.addButton("Cancel",()=>{
                if(!confirm("Are you sure you don't want to save your changes to this topic?")) return;
                this.remove();
            });
            this.addButton("Delete Topic",()=>{
                if(!confirm("Are you sure you want to delete this topic?")) return;
                var req:ClientStateRequest = {
                    view: ClientView.settings,
                    guild: view.guild.id,
                    action: {
                        action: ClientActions.DEL_TOPIC,
                        data: topic
                    }
                }
                view.socketio.emit("state",req);
                this.remove();
            });
        }else{
            this.addButton("Cancel",()=>{
                if(!confirm("Are you sure you don't want to create this topic?")) return;
                this.remove();
            });
        }

        this.addButton("Save & Close",()=>{
            var req:ClientStateRequest = {
                view: ClientView.settings,
                guild: view.guild.id,
                action: {
                    action: ClientActions.EDIT_TOPIC,
                    data: topic
                }
            }
            view.socketio.emit("state",req);
            this.remove();
        });
    }
}

export class SettingsView implements StateView {
    type = ClientView.settings
    footer:View
    header:HTMLElement
    socketio:SocketIOClient.Socket
    default_discord_channel:HTMLSelectElement
    guild:Guild
    _topics:Topic[]
    _roles:Role[]
    _channels:ChannelRef[]
    roles:HTMLElement
    topics:HTMLElement
    constructor(state:ClientState, socketio:SocketIOClient.Socket){
        var body = document.body;
        var guild = merge(state.guild,state.guilds.find(g=>g.id==state.guild.id));
        this.guild = state.guild;
        this.socketio = socketio;
        // Render the Header
        var header = body.appendChild(create("header.content-wrapper"));
        var headerCenter = header.appendChild(create(".content-center"));
        var headerCenterHoriz = headerCenter.appendChild(create(".horiz"));
        headerCenterHoriz.appendChild(create(".logo"));
        this.header = headerCenterHoriz.appendChild(create("h2",  guild.private_title));
        headerCenter.appendChild(create(".subtle", locals.version));

        var w = body.appendChild(create(".content-wrapper"));
        var c = w.appendChild(create(".content-center.split-flex[style=padding:0 1rem;box-sizing:border-box]"));
        var back = c.appendChild(create("ion-icon[name=chevron-back-outline,style=background-color: var(--foreground-color);font-size:1.5rem]"));
        back.addEventListener("click", ()=>{
            loading("rendering", true);
            this.socketio.emit("state",{
                view: ClientView.topics,
                guild: guild.id
            });
        })
        c.appendChild(create("h2[style=background-color: var(--foreground-color);padding:0 2px]","Guild Settings"));
        c.appendChild(create("ion-icon[name=null,style=font-size:1.5rem]"));

        var iconURL = makeInput(body, "Icon Override URL");
        iconURL.value = state.guild.iconURL || "";
        listenForAndApplyChange(this, iconURL, "iconURL", "", false);
        var splashURL = makeInput(body, "Splash (Background) Override URL");
        splashURL.value = state.guild.splashURL || "";
        listenForAndApplyChange(this, splashURL, "splashURL", "", false);

        var public_title = makeInput(body, "Public Title Override (Server Name)");
        public_title.value = state.guild.public_title || "";
        listenForAndApplyChange(this, public_title, "public_title", "", false);
        var public_subtitle = makeInput(body, "Public Subtitle");
        public_subtitle.value = state.guild.public_subtitle || "";
        listenForAndApplyChange(this, public_subtitle, "public_subtitle", "", false);
        var public_description = makeTextArea(body, "Public Description (What Is? Section)");
        public_description.value = state.guild.public_description || "";
        listenForAndApplyChange(this, public_description, "public_description", "", false);

        var private_title = makeInput(body, "Private Title Override");
        private_title.value = state.guild.private_title || "";
        listenForAndApplyChange(this, private_title, "private_title", "", false);
        //var private_subtitle = makeInput(body, "Private Subtitle");
        //private_subtitle.value = state.guild.private_subtitle || "";
        //listenForAndApplyChange(this, private_subtitle, "private_subtitle", "", false);
        //var private_description = makeInput(body, "Private Description");
        //private_description.value = state.guild.private_description || "";
        //listenForAndApplyChange(this, private_description, "private_description", "", false);

        var max_suggestion_title_length = makeInput(body, "Maximum Suggestion Title Length (Default 50)");
        max_suggestion_title_length.value = String(Number(state.guild.max_suggestion_title_length) || 0);
        listenForAndApplyChange(this, max_suggestion_title_length, "max_suggestion_title_length", "", true);
        var max_suggestion_description_length = makeInput(body, "Maximum Suggestion Title Length (Default 1000)");
        max_suggestion_description_length.value = String(Number(state.guild.max_suggestion_description_length) || 0);
        listenForAndApplyChange(this, max_suggestion_description_length, "max_suggestion_description_length", "", true);
        var min_suggestion_title_length = makeInput(body, "Minimum Suggestion Title Length (Default 10)");
        min_suggestion_title_length.value = String(Number(state.guild.min_suggestion_title_length) || 0);
        listenForAndApplyChange(this, min_suggestion_title_length, "min_suggestion_title_length", "", true);
        var min_suggestion_description_length = makeInput(body, "Minimum Suggestion Description Length (Default 0)");
        min_suggestion_description_length.value = String(Number(state.guild.min_suggestion_description_length) || 0);
        listenForAndApplyChange(this, min_suggestion_description_length, "min_suggestion_description_length", "", true);

        var default_discord_channel = body.appendChild(create(".floating-titled-input"));
        default_discord_channel.setAttribute("title", "Default Discord Channel");
        var default_discord_channel_wrapper = default_discord_channel.appendChild(create(".flatselect-wrapper"));
        this.default_discord_channel = default_discord_channel_wrapper.appendChild(create("select.flatselect")) as HTMLSelectElement;
        listenForAndApplyChange(this, this.default_discord_channel, "default_discord_channel", "", false);

        var theme_emphasisColor = makeInput(body, "Theme - Emphasis Color (CSS Color)");
        theme_emphasisColor.value = state.guild.theme.emphasisColor || "";
        listenForAndApplyChange(this, theme_emphasisColor, "theme", "emphasisColor", false);
        var theme_textColor = makeInput(body, "Theme - Text Color (CSS Color)");
        theme_textColor.value = state.guild.theme.textColor || "";
        listenForAndApplyChange(this, theme_textColor, "theme", "textColor", false);
        var theme_foregroundColor = makeInput(body, "Theme - Foreground Color (CSS Color)");
        theme_foregroundColor.value = state.guild.theme.foregroundColor || "";
        listenForAndApplyChange(this, theme_foregroundColor, "theme", "foregroundColor", false);
        var theme_backgroundColor = makeInput(body, "Theme - Background Color (CSS Color)");
        theme_backgroundColor.value = state.guild.theme.backgroundColor || "";
        listenForAndApplyChange(this, theme_backgroundColor, "theme", "backgroundColor", false);
        var theme_borderColor = makeInput(body, "Theme - Border Color (CSS Color)");
        theme_borderColor.value = state.guild.theme.borderColor || "";
        listenForAndApplyChange(this, theme_borderColor, "theme", "borderColor", false);

        // Roles Section
        w = body.appendChild(create(".content-wrapper"));
        c = w.appendChild(create(".content-center.split-flex[style=padding:0 1rem;box-sizing:border-box]"));
        back = c.appendChild(create("ion-icon[name=chevron-back-outline,style=background-color: var(--foreground-color);font-size:1.5rem]"));
        back.addEventListener("click", ()=>{
            loading("rendering", true);
            this.socketio.emit("state",{
                view: ClientView.topics,
                guild: guild.id
            });
        })
        c.appendChild(create("h2[style=background-color: var(--foreground-color);padding:0 2px]","Role Settings"));
        c.appendChild(create("ion-icon[name=null,style=font-size:1.5rem]"));

        // Render the Collection
        var wrapper = body.appendChild(create(".content-wrapper[style=padding: 0 1rem;box-sizing: border-box;]"));
        this.roles = wrapper.appendChild(create(".content-center[style=display:flex;position:relative;width:100%;flex-direction:column;align-items:center]"));

        // Topics Section
        w = body.appendChild(create(".content-wrapper"));
        c = w.appendChild(create(".content-center.split-flex[style=padding:0 1rem;box-sizing:border-box]"));
        back = c.appendChild(create("ion-icon[name=chevron-back-outline,style=background-color: var(--foreground-color);font-size:1.5rem]"));
        back.addEventListener("click", ()=>{
            loading("rendering", true);
            this.socketio.emit("state",{
                view: ClientView.topics,
                guild: guild.id
            });
        })
        c.appendChild(create("h2[style=background-color: var(--foreground-color);padding:0 2px]","Topic Settings"));
        var addtopic = c.appendChild(create("ion-icon[name=add,style=background-color: var(--foreground-color);font-size:1.5rem]"));
        addtopic.addEventListener("click", ()=>{
            // Create New Topic Popup
            new TopicPopup(this, {
                _id:undefined,
                guild: guild.id,
                type: TopicType.TOPIC,
                parent: undefined,
                name: "New Topic",
                permission_overrides: {},
                discord_channel: "",
                order: Date.now(),
                created: new Date(),
                updated: new Date()
            }, body);
        });

        // Render the Collection
        wrapper = body.appendChild(create(".content-wrapper[style=padding: 0 1rem;box-sizing: border-box;]"));
        this.topics = wrapper.appendChild(create(".content-center[style=display:flex;position:relative;width:100%;flex-direction:column;align-items:center]"));
        this.topics.style['margin-bottom'] = '1rem';

        this.setState(state);

        // Render the Footer
        this.footer = new UserFooter();
    }
    setState(state:ClientState){
        var guild = merge(state.guild,state.guilds.find(g=>g.id==state.guild.id));
        this.guild = state.guild;
        this._topics = state.topics;
        this._roles = state.roles;
        this._channels = state.channels;
        setTheme(merge(guild.theme,themeDefault), guild.splashURL||"/images/usagi.jpg", guild.iconURL||"/images/usagi.gif");

        this.header.innerText = guild.private_title;

        this.default_discord_channel.innerHTML = "";
        this.default_discord_channel.appendChild(create("option[value=]", "No Default Channel"));
        
        state.channels.forEach(channelref => {
            this.default_discord_channel.appendChild(create("option[value="+channelref.id+"]", channelref.name))
        });
        this.default_discord_channel.value = state.guild.default_discord_channel || "";

        //Roles Section
        this.roles.innerHTML = "";
        state.roles.forEach(role => {
            this.roles.appendChild(makeRoleRow((perms:Permissions)=>{
                role.permissions = perms.raw;
                var req:ClientStateRequest = {
                    view: ClientView.settings,
                    guild: this.guild.id,
                    action: {
                        action: ClientActions.EDIT_ROLE,
                        data: role
                    }
                }
                this.socketio.emit("state",req);
            }, role.permissions, role.name, false));
        });
        //Topics Section
        this.topics.innerHTML = "";
        state.topics.forEach(topic => {
            this.topics.appendChild(makeTopicRow(this, topic));
        });
    }
    onDestroy(){}
}