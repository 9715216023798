import { Suggestion, Topic, Guild, Role, Comment } from "./shared";
import { Permissions } from "./permissions";

export enum ClientView {
    unauthorized = 0,
    guilds = 1,
    topics = 2,
    suggestion = 3,
    settings = 4
}
export interface ClientStateRequest {
    /**
     * The view we want to look at
     *
     * @type {ClientView}
     * @memberof ClientStateRequest
     */
    view:ClientView,
    guild?:string,
    topic?:string,
    sort?:any,
    query?:string,
    suggestion?:string,
    action?:ClientAction
}
export interface ClientAction {
    action:ClientActions
    data?: Suggestion | Comment | Topic | Guild | Role | PropEdit
}
export interface PropEdit  {prop1:string,prop2:string,value:string|number}
export enum ClientActions {
    // 0x0 - Suggestion Actions
    ADD_SUGGESTION = 0x00,
    DEL_SUGGESTION = 0x01,
    EDIT_SUGGESTION = 0x02,
    UPVOTE_SUGGESTION = 0x03,
    DOWNVOTE_SUGGESTION = 0x04,
    // 0x1 - Comment Actions
    ADD_COMMENT = 0x10,
    DEL_COMMENT = 0x11,
    EDIT_COMMENT = 0x13,
    UPVOTE_COMMENT = 0x14,
    DOWNVOTE_COMMENT = 0x15,
    // 0x2 - Topic Actions
    ADD_TOPIC = 0x20,
    DEL_TOPIC = 0x21,
    EDIT_TOPIC = 0x22,
    // 0x3 - Guild Actions
    EDIT_GUILD = 0x32,
    EXPORT_CSV = 0x33,
    // 0x4 - Role Actions
    EDIT_ROLE = 0x42,
}
export interface ClientState {
    /**
     * What view this data should be displayed with
     *
     * @type {ClientView}
     * @memberof ClientState
     */
    view:ClientView
    /**
     * Guilds the Client is a part of
     *
     * @type {Guild[]}
     * @memberof ClientState
     */
    guilds:Guild[]
    /**
     * Guild the client is focused on
     *
     * @type {Guild}
     * @memberof ClientState
     */
    guild?:Guild
    /**
     * Guild Channels, Only needed in the settings view.
     *
     * @type {ChannelRef[]}
     * @memberof ClientState
     */
    channels:ChannelRef[]
    /**
     * Topics if a guild has been selected
     *
     * @type {Topic[]}
     * @memberof ClientState
     */
    topics:Topic[]
    /**
     * Topic ID the client is focused on
     *
     * @type {string}
     * @memberof ClientState
     */
    topic?:string
    /**
     * Roles if a guild has been selected
     *
     * @type {Role[]}
     * @memberof ClientState
     */
    roles:Role[]
    /**
     * My base permissions based off of my roles
     *
     * @type {Permissions.Raw}
     * @memberof ClientState
     */
    myperms:Permissions.Raw
    /**
     * My role IDs
     *
     * @type {string[]}
     * @memberof ClientState
     */
    myroles:string[]
    /**
     * Client search results
     *
     * @type {Suggestion[]}
     * @memberof ClientState
     */
    suggestions:Suggestion[]
    /**
     * Focused Suggestion
     *
     * @type {Suggestion[]}
     * @memberof ClientState
     */
    suggestion?:Suggestion
    similarsuggestions:Suggestion[]
    /**
     * Focused Suggestion's Comments
     *
     * @type {Comment[]}
     * @memberof ClientState
     */
    comments:Comment[]
    message?: ClientMessage
}
export interface ChannelRef {
    id: string,
    name: string
}
export interface ClientMessage {
    text: string
    type: ClientMessageType
}
export enum ClientMessageType {
    INFO = 0,
    WARNING = 1,
    ERROR = 2,
}

// Helpers
export function clientStateRequestFrom(path:string):ClientStateRequest {
    var _path = (path||"").split("/").filter(p=>p);
    console.log("Path Parts:",_path);
    // Guilds
    if(_path.length == 0) return {
        view: ClientView.guilds
    }
    // Topics
    if(_path.length == 1) return {
        view: ClientView.topics,
        guild: _path[0]
    }
    // Topic or Settings
    if(_path.length == 2) {
        if(_path[1] == "settings"){
            return {
                view: ClientView.settings,
                guild: _path[0]
            }
        }else{
            return {
                view: ClientView.topics,
                guild: _path[0],
                topic: _path[1]
            }
        }
    }
    // Suggestion
    if(_path.length >= 3) return {
        view: ClientView.suggestion,
        guild: _path[0],
        topic: _path[1],
        suggestion: _path[2]
    }
    // Default... Literally no way to get here but I _try_ to be a good programmer?
    return {
        view: ClientView.guilds
    }
}
export function urlPathFromClientState(state:ClientState):string {
    var path:string[] = [];

    if(!state.guild) return "/";

    switch(state.view){
        case ClientView.topics:
            path.push(state.guild.id);
            if(state.topic) path.push(state.topic);
            break;
        case ClientView.suggestion:
            path.push(state.guild.id);
            if(state.suggestion) path.push(state.suggestion.topic);
            if(state.suggestion) path.push(state.suggestion._id);
            break;
        case ClientView.settings:
            path.push(state.guild.id);
            path.push("settings");
            break;
    }

    return "/"+path.join("/")
}