import { ClientView, ClientState } from "../../../node/src/shared/client";
import { create } from "../api";
import { Locals } from "../../../node/src/shared/locals";
import { kofiwidget2 } from "../kofiwidget2";
import { StateView } from "./support";

//#region Globals Setup
declare var locals:Locals;
declare var SmoothScroll:any;
var smoothscroll = new SmoothScroll('a[href*="#"]');

export class PublicPage implements StateView {
    type = ClientView.unauthorized
    scrolllistener:any
    constructor(state:ClientState){
        var body = document.body;

        // Render the Header
        var header = body.appendChild(create("header.content-wrapper"));
        var headerCenter = header.appendChild(create(".content-center"));
        var headerCenterHoriz = headerCenter.appendChild(create(".horiz"));
        headerCenterHoriz.appendChild(create(".logo"));
        headerCenterHoriz.appendChild(create("h2", locals.short_site_name));
        headerCenter.appendChild(create(".subtle", locals.version));

        // Render the coverpage
        var page1 = body.appendChild(create("page.content-wrapper"));
        var page1Center = page1.appendChild(create(".content-center"));
        page1Center.appendChild(create("h2.emphasis", locals.public_title));
        if(locals.public_subtitle) page1Center.appendChild(create("h3.emphasis", locals.public_subtitle));
        var signin = page1Center.appendChild(create("button.emphasis", "Sign in with Discord"));
        signin.addEventListener("click",function(){
            window.location.href = "/discord?path="+location.pathname;
        });
        var add2server = page1Center.appendChild(create("button", "Add to your server"));
        add2server.addEventListener("click",function(){
            window.open(locals.bot_link,'_blank');
        });
        if(locals.public_description){
            var more = page1Center.appendChild(create(".more"));
            var span = more.appendChild(create("span", `What is ${locals.public_title}?`));
            var icon = more.appendChild(create("ion-icon[name=chevron-down-outline]"));
            span.addEventListener("click",()=>smoothscroll.animateScroll(window.innerHeight - 80));
            icon.addEventListener("click",()=>smoothscroll.animateScroll(window.innerHeight - 80));

            this.scrolllistener = function(){
                if(!more) return;
                more.style.opacity = `${(100-Math.max(Math.min((document.documentElement.scrollTop || document.body.scrollTop)-40, 100),0))/100}`
            }
            document.addEventListener('scroll',this.scrolllistener);
            this.scrolllistener();
        }

        // Render the about page
        if(locals.public_description){
            var page2 = body.appendChild(create("filled.content-wrapper#what-is-usagi"));
            var page2Center = page2.appendChild(create(".content-center"));
            page2Center.appendChild(create("h1",`What is ${locals.public_title}?`));
            page2Center.appendChild(create("p", locals.public_description));
            body.appendChild(create("br"));
        }

        // Render Features page
        if(location.pathname == "/"){
            var page3 = body.appendChild(create("filled.content-wrapper"));
            var page3Center = page3.appendChild(create(".content-center"));
            page3Center.appendChild(create("h1",`Features`));
            page3Center.appendChild(create("p",`• Customizable theming for your server
            • Fuzzy Real-Time Entry Search to reduce duplicates
            • Full tiered permissions system
            • Multiple Suggestion Discord Channels`));
            body.appendChild(create("br"));

            var page4 = body.appendChild(create("filled.content-wrapper"));
            var page4Center = page4.appendChild(create(".content-center"));
            page4Center.appendChild(create("h1",`Pricing`));
            page4Center.appendChild(create("p",`No pricing for now! This is a passion project, so feel free to support me on ko-fi so I can keep it runnning and updated!`));
            page4Center.appendChild(create("br"));
            
            page4Center.innerHTML+=kofiwidget2.getHTML();

            body.appendChild(create("br"));
        }

        // Render Footer
        var page5 = body.appendChild(create("filled.content-wrapper"));
        var page5Center = page5.appendChild(create(".content-center"));
        page5Center.style['padding-bottom'] = 'calc(var(--safe-area-inset-bottom) + 1rem)';
        page5Center.appendChild(create("br"));
        page5Center.appendChild(create("p",`© Usagi Suggestions - ${Math.max(new Date().getFullYear(),2020)}`));
        page5Center.appendChild(create("br"));
    }
    setState(state:ClientState):void{}
    onDestroy(){
        if(this.scrolllistener) window.removeEventListener("scroll", this.scrolllistener);
    }
}