import { StateView, View, UserFooter } from "./support";
import { ClientState, ClientView, ClientStateRequest, ClientActions } from "../../../node/src/shared/client";
import { create, loading } from "../api";
import { Locals } from "../../../node/src/shared/locals";
import { merge, TopicType, Suggestion, Topic, Role, compilePermissionsResults } from "../../../node/src/shared/shared";
import { Permissions } from "../../../node/src/shared/permissions";
import { Popup } from "../popup";
import { textFieldAutoSize } from "./SettingsView";

declare var locals:Locals;

export class TopicView implements StateView {
    type = ClientView.topics
    footer:View
    search:HTMLInputElement
    topics:HTMLSelectElement
    topicmap:{[key:string]:Topic} = {}
    description:HTMLTextAreaElement
    submitbuttons:HTMLElement
    createbutton:HTMLButtonElement
    count:HTMLElement
    page:HTMLElement
    socketio:SocketIOClient.Socket
    state:ClientState
    sortby:any = {created:-1}
    constructor(state:ClientState, socketio:SocketIOClient.Socket){
        var body = document.body;
        this.state = state;
        var guild = merge(state.guild,state.guilds.find(g=>g.id==state.guild.id));
        this.socketio = socketio;

        // Render the Header
        var header = body.appendChild(create("header.content-wrapper"));
        var headerCenter = header.appendChild(create(".content-center"));
        var headerCenterHoriz = headerCenter.appendChild(create(".horiz"));
        var logo = headerCenterHoriz.appendChild(create(".logo"));
        logo.addEventListener("click",()=>{
            var req:ClientStateRequest = {
                view: ClientView.guilds
            };
            loading("rendering", true);
            this.socketio.emit("state",req);
        });
        headerCenterHoriz.appendChild(create("h2",  guild.private_title));
        headerCenter.appendChild(create(".subtle", locals.version));

        var _search = body.appendChild(create(".topic-field.content-wrapper.sticky"));
        this.search = _search.appendChild(create("input[placeholder=Search / Suggestion Title]")) as HTMLInputElement;
        this.search.addEventListener("input",()=>{
            if(this.search.value) {
                this.sortby = {created:-1};
                sortby.style.display = "none";
            }else{
                sortby.style.display = "";
            }
            var req:ClientStateRequest = {
                view: ClientView.topics,
                guild: guild.id,
                topic: this.topics.value || undefined,
                query: this.search.value || undefined,
                sort: this.sortby
            }
            this.socketio.emit("state",req);
        });
        var sortby = _search.appendChild(create("ion-icon[name=funnel-sharp]"));
        sortby.addEventListener("click",()=>{
            var popup = new Popup(body);
            var update = ()=>{
                var req:ClientStateRequest = {
                    view: ClientView.topics,
                    guild: guild.id,
                    topic: this.topics.value || undefined,
                    query: this.search.value || undefined,
                    sort: this.sortby
                }
                this.socketio.emit("state",req);
                popup.remove();
            }
            popup.setTitle("Sort Results By:");
            popup.addButton("Oldest to Newest",()=>{
                this.sortby = {"created":1};
                update();
            });
            popup.addButton("Newest to Oldest",()=>{
                this.sortby = {"created":-1};
                update();
            });
            popup.addButton("Most Positive",()=>{
                this.sortby = {"cachedStats.positivity":-1};
                update();
            });
            popup.addButton("Least Positive",()=>{
                this.sortby = {"cachedStats.positivity":-1};
                update();
            });
            popup.addButton("Most Voted",()=>{
                this.sortby = {"cachedStats.votes":-1};
                update();
            });
            popup.addButton("Least Voted",()=>{
                this.sortby = {"cachedStats.votes":1};
                update();
            });
            popup.addButton("Most Up-Voted",()=>{
                this.sortby = {"cachedStats.upvotes":-1};
                update();
            });
            popup.addButton("Least Up-Voted",()=>{
                this.sortby = {"cachedStats.upvotes":1};
                update();
            });
            popup.addButton("Most Down-Voted",()=>{
                this.sortby = {"cachedStats.downvotes":-1};
                update();
            });
            popup.addButton("Least Down-Voted",()=>{
                this.sortby = {"cachedStats.downvotes":1};
                update();
            });
            popup.addButton("Cancel",popup.remove);
        });

        var _topics = body.appendChild(create(".topic-field.content-wrapper"));
        var topics = _topics.appendChild(create(".flatselect-wrapper"));
        this.topics = topics.appendChild(create("select.flatselect")) as HTMLSelectElement;
        this.topics.addEventListener("change",()=>{
            var req:ClientStateRequest = {
                view: ClientView.topics,
                guild: guild.id,
                topic: this.topics.value || undefined,
                query: this.search.value || undefined
            }
            this.socketio.emit("state",req);
        })

        var _description = body.appendChild(create(".topic-field.content-wrapper"));
        this.description = _description.appendChild(create("textarea[rows=1,style=display:none,placeholder=Suggestion Description]")) as HTMLTextAreaElement;
        textFieldAutoSize.call(this.description);
        this.description.addEventListener("input",textFieldAutoSize);

        var _buttons = body.appendChild(create(".topic-field.content-wrapper"));
        this.submitbuttons = _buttons.appendChild(create(".content-center[style=display:none]"));

        var cancel = this.submitbuttons.appendChild(create("button.flat[style=width:50%;padding:1rem;height:auto]", "Cancel"));
        cancel.addEventListener("click",()=>{
            // Hide Description and Buttons, and show Create
            this.description.style.display = "none";
            this.submitbuttons.style.display = "none";
            this.createbutton.style.display = "";
        });

        var submit = this.submitbuttons.appendChild(create("button.flat.emphasis[style=width:50%;padding:1rem;height:auto]", "Submit Suggestion!"));
        submit.addEventListener("click",()=>{
            guild = merge(this.state.guild,this.state.guilds.find(g=>g.id==this.state.guild.id));
            // Validate Title
            if(!this.search.value || this.search.value.length < guild.min_suggestion_title_length){
                var popup = new Popup(body);
                popup.setTitle("Error");
                popup.setBody(`Your suggestion title must be at least ${guild.min_suggestion_title_length} characters long.`);
                popup.addButton("Dismiss",popup.remove);
                return;
            }
            if(this.search.value.length > guild.max_suggestion_title_length){
                var popup = new Popup(body);
                popup.setTitle("Error");
                popup.setBody(`Your suggestion title must be less than ${guild.max_suggestion_title_length} characters long.`);
                popup.addButton("Dismiss",popup.remove);
                return;
            }
            // Validate Description
            if(this.description.value.length < guild.min_suggestion_description_length){
                var popup = new Popup(body);
                popup.setTitle("Error");
                popup.setBody(`Your suggestion description must be at least ${guild.min_suggestion_description_length} characters long.`);
                popup.addButton("Dismiss",popup.remove);
                return;
            }
            if(this.description.value.length > guild.max_suggestion_description_length){
                var popup = new Popup(body);
                popup.setTitle("Error");
                popup.setBody(`Your suggestion description must be less than ${guild.max_suggestion_description_length} characters long.`);
                popup.addButton("Dismiss",popup.remove);
                return;
            }
            // Validate Topic
            if(!this.topics.value){
                var popup = new Popup(body);
                popup.setTitle("Error");
                popup.setBody(`Please choose a topic for your suggestion.`);
                popup.addButton("Dismiss",popup.remove);
                return;
            }
            var req:ClientStateRequest = {
                view: ClientView.topics,
                guild: guild.id,
                topic: this.state.topic,
                action: {
                    action: ClientActions.ADD_SUGGESTION,
                    data: {
                        _id:undefined,
                        guild:guild.id,
                        topic:this.topics.value,
                        title:this.search.value,
                        description:this.description.value,
                        tags:[],
                        author: undefined,
                        votes: undefined,
                        soundex: [],
                        number: 0,
                        cachedStats: {
                            votes:0,upvotes:0,
                            downvotes:0,positivity:0,
                            comments: 0
                        },
                        created: new Date(),
                        updated: new Date()
                    }
                }
            }
            // Validate Similar Results
            if(this.state.suggestions.length){
                var popup = new Popup(body);
                popup.setTitle("Error");
                popup.setBody(`Please make sure that this is not a duplicate suggestion`);
                popup.addButton("Dimiss and don't create.",popup.remove);
                popup.addButton("Create Anyways",()=>{
                    // Send Request to the Server
                    popup.remove();
                    loading("rendering",true);
                    this.socketio.emit("state",req);
                });
                return;
            }else{
                // Send Request to the Server
                loading("rendering",true);
                this.socketio.emit("state",req);
            }
        });

        // TODO: Add Sortby somewhere

        var column = body.appendChild(create("div[style=display:flex;position:relative;width:100%;flex-direction:column;align-items:center]"))

        if(state.myperms.allow & Permissions.Bit.SUGGEST){
            this.createbutton = column.appendChild(create("button.center[style=margin:1rem 0]", "Create a new suggestion!")) as HTMLButtonElement;
            this.createbutton.addEventListener("click", ()=>{
                // Show Description and Buttons, and hide Create
                this.description.style.display = "";
                this.submitbuttons.style.display = "flex";
                this.createbutton.style.display = "none";
                this.search.focus();
            });
        }

        if(state.myperms.allow & Permissions.Bit.MANAGE_GUILD){
            var settings = column.appendChild(create("button.center[style=margin:1rem 0]", "Open Settings"));
            settings.addEventListener("click",()=>{
                loading("rendering",true);
                this.socketio.emit("state",{
                    view: ClientView.settings,
                    guild: state.guild.id
                });
            });
        }

        // Result Count
        var countwrapper = body.appendChild(create(".content-wrapper"));
        var countwrappercenter = countwrapper.appendChild(create(".content-center[style=display:flex;align-items:center;justify-content:center]"));
        this.count = countwrappercenter.appendChild(create("span[style=margin:1rem 0;text-align:center;background-color:var(--foreground-color);padding:0 2px 2px;]",state.suggestions.length+` Suggestion${state.suggestions.length==1?"":"s"} found.`));

        // Render the Collection
        var wrapper = body.appendChild(create(".content-wrapper"));
        this.page = wrapper.appendChild(create(".content-center[style=display:flex;position:relative;width:100%;flex-direction:column;align-items:center]"));

        this.setState(state);

        // Render the Footer
        this.footer = new UserFooter();
    }
    setState(state:ClientState):void{
        // Setup Topics Dropdown
        this.state = state;
        this.topicmap = {};
        state.topics.forEach(topic => {
            this.topicmap[topic._id] = topic;
        });
        //var perms = compilePermissionsResults(state.myroles, state.roles, this.topicmap[this.topics.value],this.topicmap[this.topics.value]&&this.topicmap[this.topicmap[this.topics.value].parent]||undefined);

        this.topics.innerHTML = "";
        this.topics.appendChild(create("option[value=]", "All Topics"));
        var groups:{[key:string]:HTMLElement} = null;
        console.log("Topics:",state.topics);
        state.topics.forEach(topic=>{
            if(topic.type == TopicType.CATEGORY){
                groups[topic._id] = this.topics.appendChild(create("optgroup", topic.name));
            }else if(topic.type == TopicType.TOPIC && !topic.parent){
                this.topics.appendChild(create("option[value="+topic._id+"]", topic.name));
            }
        });
        state.topics.forEach(topic=>{
            if(TopicType.TOPIC && topic.parent){
                groups[topic.parent]?.appendChild(create("option[value="+topic._id+"]", topic.name));
            }
        });
        
        this.topics.value = state.topic || "";

        // Suggestions Count
        this.count.innerText = state.suggestions.length+` Suggestion${state.suggestions.length==1?"":"s"} found.`;
        // Suggestions List
        //FUTURE: Convert Scroll Library to TS or something
        this.page.innerHTML = "";
        state.suggestions.forEach(suggestion=>{
            //TODO: Render Suggestions
            this.page.appendChild(makeSuggestion(this,suggestion));
        });
    }
    onDestroy(){
        this.footer.onDestroy();
    }
}

function makeSuggestion(view:TopicView, suggestion:Suggestion):HTMLElement {
    var perms = compilePermissionsResults(view.state.myroles, view.state.roles, view.topicmap[suggestion.topic],view.topicmap[view.topicmap[suggestion.topic].parent]);
    var c = create(".suggestion");
    c.appendChild(create(
        ".suggestion-header", 
        "#"+suggestion.number+" - "+(view.topicmap[suggestion.topic]&&view.topicmap[suggestion.topic].parent&&view.topicmap[view.topicmap[suggestion.topic].parent]?view.topicmap[view.topicmap[suggestion.topic].parent].name+"/":"")+(view.topicmap[suggestion.topic] || {name:"???"}).name
    ));
    var a = c.appendChild(create("a.suggestion-title[style=text-decoration:none]", suggestion.title)) as HTMLLinkElement;
    a.href = "/"+suggestion.guild+"/"+suggestion.topic+"/"+suggestion._id;
    a.addEventListener("click",(e)=>{
        e.preventDefault();
        var req:ClientStateRequest = {
            view: ClientView.suggestion,
            guild: view.state.guild.id,
            suggestion: suggestion._id,
        }
        loading("rendering",true);
        view.socketio.emit("state",req);
    });

    c.appendChild(create(".suggestion-description", suggestion.description));
    var f = c.appendChild(create(".suggestion-footer"));
    var l = f.appendChild(create(""));

    var canvote = perms.get(Permissions.Bit.VOTE, 1);

    //if(perms.get(Permissions.Bit.VOTE, 1)){
        var upbutton = l.appendChild(create("ion-icon[name=thumbs-up-sharp]"));
        upbutton.style.opacity = suggestion.votes&&suggestion.votes.up&&suggestion.votes.up.indexOf(locals.discord.id)>-1?"1":"0.67";
        var uptext = l.appendChild(create("span[style=margin-left:2px;margin-right:0.5rem]",(suggestion.votes&&suggestion.votes.up&&suggestion.votes.up.length||"0")+""));
        var downbutton = l.appendChild(create("ion-icon[name=thumbs-down-sharp]"));
        downbutton.style.opacity = suggestion.votes&&suggestion.votes.down&&suggestion.votes.down.indexOf(locals.discord.id)>-1?"1":"0.67";
        var downtext = l.appendChild(create("span[style=margin-left:2px;margin-right:0.5rem]",(suggestion.votes&&suggestion.votes.down&&suggestion.votes.down.length||"0")+""));
    //}
    l.appendChild(create("ion-icon[name=chatbubble-sharp]"));
    l.appendChild(create("span",suggestion.cachedStats.comments+""));
    var r = f.appendChild(create(""));
    
    if(suggestion.author.id == locals.discord.id || perms.get(Permissions.Bit.DELETE_SUGGESTIONS, 1)){
        var trash = r.appendChild(create("ion-icon[name=trash-sharp]"));
        trash.addEventListener("click",()=>{
            var popup = new Popup(document.body);
            popup.setTitle("WANRING");
            popup.setBody(`Are you sure you want to delete the suggestion "${suggestion.title}"?.`);
            popup.addButton("Delete Suggestion",()=>{
                popup.remove();
                var req:ClientStateRequest = {
                    view: ClientView.topics,
                    guild: view.state.guild.id,
                    topic: view.topics.value || undefined,
                    query: view.search.value || undefined,
                    action:{
                        action: ClientActions.DEL_SUGGESTION,
                        data: suggestion
                    }
                }
                view.socketio.emit("state",req);
            });
            popup.addButton("Cancel",popup.remove);
        });
    }

    r.appendChild(create("span[style=margin-left:2px;margin-right:0.5rem]",`${suggestion.author.tag}${suggestion.author.nickname?" ["+suggestion.author.nickname+"]":""}`));
    var img = r.appendChild(create(".img"));
    img.style['background-image'] = `url(${suggestion.author.icon})`;

    if(canvote) upbutton.addEventListener("click",()=>{
        upbutton.style.opacity = "1";
        downbutton.style.opacity = "0.67";
        var req:ClientStateRequest = {
            view: ClientView.topics,
            guild: view.state.guild.id,
            topic: view.topics.value,
            query: view.search.value || "",
            action: {
                action: ClientActions.UPVOTE_SUGGESTION,
                data: suggestion
            }
        };
        view.socketio.emit("state",req);
    });
    if(canvote) downbutton.addEventListener("click",()=>{
        upbutton.style.opacity = "0.67";
        downbutton.style.opacity = "1";
        var req:ClientStateRequest = {
            view: ClientView.topics,
            guild: view.state.guild.id,
            topic: view.topics.value,
            query: view.search.value || "",
            action: {
                action: ClientActions.DOWNVOTE_SUGGESTION,
                data: suggestion
            }
        };
        view.socketio.emit("state",req);
    });

    return c;
}