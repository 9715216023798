import { StateView, View, UserFooter } from "./support";
import { ClientState, ClientView, ClientStateRequest, ClientActions } from "../../../node/src/shared/client";
import { create, loading } from "../api";
import { Locals } from "../../../node/src/shared/locals";
import { merge, TopicType, Topic, compilePermissionsResults, Comment, Suggestion } from "../../../node/src/shared/shared";
import { Permissions } from "../../../node/src/shared/permissions";
import { Popup } from "../popup";
import { textFieldAutoSize } from "./SettingsView";

declare var locals:Locals;

//TODO: Suggestion Detail View
export class SuggestionView implements StateView {
    type = ClientView.suggestion
    footer:View
    search:HTMLInputElement
    topics:HTMLSelectElement
    topicmap:{[key:string]:Topic} = {}
    _footer:HTMLElement
    description:HTMLTextAreaElement
    submitbuttons:HTMLElement
    editbutton:HTMLButtonElement
    page:HTMLElement

    commentdescription:HTMLTextAreaElement
    commentsubmitbuttons:HTMLElement

    similarpage:HTMLElement
    createcommentbutton:HTMLButtonElement
    socketio:SocketIOClient.Socket
    state:ClientState
    editing:boolean = false
    editingcomment:boolean = false
    constructor(state:ClientState, socketio:SocketIOClient.Socket){
        var body = document.body;
        var guild = merge(state.guild,state.guilds.find(g=>g.id==state.guild.id));
        this.topicmap = {};
        state.topics.forEach(topic => {
            this.topicmap[topic._id] = topic;
        });
        var perms = compilePermissionsResults(state.myroles, state.roles, this.topicmap[state.suggestion.topic],this.topicmap[this.topicmap[state.suggestion.topic].parent]);

        this.socketio = socketio;
        // Render the Header
        var header = body.appendChild(create("header.content-wrapper"));
        var headerCenter = header.appendChild(create(".content-center"));
        var headerCenterHoriz = headerCenter.appendChild(create(".horiz"));
        var logo = headerCenterHoriz.appendChild(create(".logo"));
        logo.addEventListener("click",()=>{
            var req:ClientStateRequest = {
                view: ClientView.topics,
                guild: guild.id,
                topic: state.suggestion.topic,
            };
            loading("rendering", true);
            this.socketio.emit("state",req);
        });
        headerCenterHoriz.appendChild(create("h2",  guild.private_title));
        headerCenter.appendChild(create(".subtle", locals.version));

        // Title
        var _search = body.appendChild(create(".topic-field.content-wrapper.sticky"));
        this.search = _search.appendChild(create("input[placeholder=Suggestion Title,style=font-weight:bolder;font-size:1.2rem]")) as HTMLInputElement;
        this.search.disabled = true;
        // Topic
        var _topics = body.appendChild(create(".topic-field.content-wrapper"));
        var topics = _topics.appendChild(create(".flatselect-wrapper"));
        this.topics = topics.appendChild(create("select.flatselect[style=padding:0.25rem 0.8rem;font-size:0.8rem;font-weight:lighter]")) as HTMLSelectElement;
        this.topics.disabled = true;
        // Description
        var _description = body.appendChild(create(".topic-field.content-wrapper"));
        this.description = _description.appendChild(create("textarea[rows=1,style=placeholder=Suggestion Description]")) as HTMLTextAreaElement;
        this.description.addEventListener("input",textFieldAutoSize,false);
        this.description.disabled = true;
        // Vote and author bar
        this._footer = body.appendChild(create(".topic-field.content-wrapper.suggestion-footer"));
        // Buttons
        var _buttons = body.appendChild(create(".topic-field.content-wrapper"));
        this.submitbuttons = _buttons.appendChild(create(".content-center[style=display:none]"));
        var cancel = this.submitbuttons.appendChild(create("button.flat[style=width:50%;padding:1rem;height:auto]", "Cancel"));
        cancel.addEventListener("click",()=>{
            // Hide Description and Buttons, and show Create
            this.search.disabled = true;
            this.topics.disabled = true;
            this.description.disabled = true;
            this.submitbuttons.style.display = "none";
            this.editbutton.style.display = "";
            this.editing = false;
        });

        var submit = this.submitbuttons.appendChild(create("button.flat.emphasis[style=width:50%;padding:1rem;height:auto]", "Save Changes!"));
        submit.addEventListener("click",()=>{
            guild = merge(this.state.guild,this.state.guilds.find(g=>g.id==this.state.guild.id));
            // Validate Title
            if(!this.search.value || this.search.value.length < guild.min_suggestion_title_length){
                var popup = new Popup(body);
                popup.setTitle("Error");
                popup.setBody(`Your suggestion title must be at least ${guild.min_suggestion_title_length} characters long.`);
                popup.addButton("Dismiss",popup.remove);
                return;
            }
            if(this.search.value.length > guild.max_suggestion_title_length){
                var popup = new Popup(body);
                popup.setTitle("Error");
                popup.setBody(`Your suggestion title must be less than ${guild.min_suggestion_title_length} characters long.`);
                popup.addButton("Dismiss",popup.remove);
                return;
            }
            // Validate Description
            if(this.description.value.length < guild.min_suggestion_description_length){
                var popup = new Popup(body);
                popup.setTitle("Error");
                popup.setBody(`Your suggestion description must be at least ${guild.min_suggestion_description_length} characters long.`);
                popup.addButton("Dismiss",popup.remove);
                return;
            }
            if(this.description.value.length > guild.max_suggestion_description_length){
                var popup = new Popup(body);
                popup.setTitle("Error");
                popup.setBody(`Your suggestion description must be less than ${guild.max_suggestion_description_length} characters long.`);
                popup.addButton("Dismiss",popup.remove);
                return;
            }
            // Validate Topic
            if(!this.topics.value){
                var popup = new Popup(body);
                popup.setTitle("Error");
                popup.setBody(`Please choose a topic for your suggestion.`);
                popup.addButton("Dismiss",popup.remove);
                return;
            }
            var req:ClientStateRequest = {
                view: ClientView.suggestion,
                guild: guild.id,
                suggestion: this.state.suggestion._id,
                action: {
                    action: ClientActions.EDIT_SUGGESTION,
                    data: {
                        _id:this.state.suggestion._id,
                        guild:guild.id,
                        topic:this.topics.value,
                        title:this.search.value,
                        description:this.description.value,
                        tags:[],
                        author: undefined,
                        votes: undefined,
                        soundex: [],
                        number: 0,
                        cachedStats: {
                            votes:0,upvotes:0,
                            downvotes:0,positivity:0,
                            comments: 0
                        },
                        created: new Date(),
                        updated: new Date()
                    }
                }
            }
            // Hide Description and Buttons, and show Create
            this.search.disabled = true;
            this.topics.disabled = true;
            this.description.disabled = true;
            this.submitbuttons.style.display = "none";
            this.editbutton.style.display = "";
            this.editing = false;
            // Send Request to the Server
            loading("rendering",true);
            this.socketio.emit("state",req);
        });
        // Edit Button
        if(state.suggestion.author.id == locals.discord.id || perms.get(Permissions.Bit.EDIT_SUGGESTION_TITLES, 1) || perms.get(Permissions.Bit.EDIT_SUGGESTION_CONTENTS, 1) || perms.get(Permissions.Bit.MOVE_SUGGESTIONS, 1)){
            var column = body.appendChild(create("div[style=display:flex;position:relative;width:100%;flex-direction:column;align-items:center]"))
            this.editbutton = column.appendChild(create("button.center[style=margin:1rem 0]", "Edit this suggestion")) as HTMLButtonElement;
            this.editbutton.addEventListener("click", ()=>{
                // Show Description and Buttons, and hide Create
                if(state.suggestion.author.id == locals.discord.id || perms.get(Permissions.Bit.EDIT_SUGGESTION_TITLES, 1))
                    this.search.disabled = false;
                if(state.suggestion.author.id == locals.discord.id || perms.get(Permissions.Bit.MOVE_SUGGESTIONS, 1))
                    this.topics.disabled = false;
                if(state.suggestion.author.id == locals.discord.id || perms.get(Permissions.Bit.EDIT_SUGGESTION_CONTENTS, 1))
                    this.description.disabled = false;
                this.submitbuttons.style.display = "flex";
                this.editbutton.style.display = "none";
                this.editing = true;
            });
        }
        // Comments Container
        var comment_wrapper = body.appendChild(create(".content-wrapper"));
        this.page = comment_wrapper.appendChild(create(".content-center[style=display:flex;position:relative;width:100%;flex-direction:column;align-items:center]"));
        // Comment Description
        var _comment_description = body.appendChild(create(".topic-field.content-wrapper[style=display:none]"));
        this.commentdescription = _comment_description.appendChild(create("textarea[rows=1,style=min-height:100px,placeholder=New Comment]")) as HTMLTextAreaElement;
        textFieldAutoSize.call(this.commentdescription);
        this.commentdescription.addEventListener("input",textFieldAutoSize);
        // Comment Buttons
        var comment_buttons = body.appendChild(create(".topic-field.content-wrapper[style=display:none]"));
        this.commentsubmitbuttons = comment_buttons.appendChild(create(".content-center"));
        cancel = this.commentsubmitbuttons.appendChild(create("button.flat[style=width:50%;padding:1rem;height:auto]", "Cancel"));
        cancel.addEventListener("click",()=>{
            _comment_description.style.display = "none";
            comment_buttons.style.display = "none";
            this.editingcomment = false;
        });
        submit = this.commentsubmitbuttons.appendChild(create("button.flat.emphasis[style=width:50%;padding:1rem;height:auto]", "Add Comment"));
        submit.addEventListener("click",()=>{
            guild = merge(this.state.guild,this.state.guilds.find(g=>g.id==this.state.guild.id));
            // Validate Description
            if(!this.commentdescription.value.length || this.commentdescription.value.length < Math.max(guild.min_suggestion_description_length, guild.min_suggestion_title_length)){
                var popup = new Popup(body);
                popup.setTitle("Error");
                popup.setBody(`Your comment must be at least ${Math.max(guild.min_suggestion_description_length, guild.min_suggestion_title_length)} characters long.`);
                popup.addButton("Dismiss",popup.remove);
                return;
            }
            if(this.commentdescription.value.length > guild.max_suggestion_description_length){
                var popup = new Popup(body);
                popup.setTitle("Error");
                popup.setBody(`Your comment must be less than ${guild.max_suggestion_description_length} characters long.`);
                popup.addButton("Dismiss",popup.remove);
                return;
            }
            var req:ClientStateRequest = {
                view: ClientView.suggestion,
                guild: guild.id,
                suggestion: this.state.suggestion._id,
                action: {
                    action: ClientActions.ADD_COMMENT,
                    data: {
                        _id: undefined,
                        guild:guild.id,
                        suggestion:this.state.suggestion._id,
                        description: this.commentdescription.value,
                        author: undefined,
                        votes: {
                            up:[],
                            down:[],
                        },
                        created: new Date(),
                        updated: new Date()
                    }
                }
            }
            _comment_description.style.display = "none";
            comment_buttons.style.display = "none";
            this.editingcomment = false;
            loading("rendering",true);
            this.socketio.emit("state",req);
        });
        // Add Comment Button
        if(perms.get(Permissions.Bit.COMMENT, 1)){
            var column = body.appendChild(create("div[style=display:flex;position:relative;width:100%;flex-direction:column;align-items:center]"))
            this.createcommentbutton = column.appendChild(create("button.center[style=margin:1rem 0]", "Add a comment!")) as HTMLButtonElement;
            this.createcommentbutton.addEventListener("click",()=>{
                _comment_description.style.display = "";
                comment_buttons.style.display = "";
                this.commentdescription.focus();
                this.editingcomment = true;
            });
        }
        // Similar Section
        var wrapper = body.appendChild(create(".content-wrapper"));
        this.similarpage = wrapper.appendChild(create(".content-center[style=display:flex;position:relative;width:100%;flex-direction:column;align-items:center]"));

        this.setState(state);

        // Render the Footer
        this.footer = new UserFooter();
    }
    setState(state:ClientState){
        // Freeze updates if we are editing
    
        var perms = compilePermissionsResults(state.myroles, state.roles, this.topicmap[state.suggestion.topic],this.topicmap[this.topicmap[state.suggestion.topic].parent]);

        if(!this.editing){
            // Setup Topics Dropdown
            this.state = state;
            this.topicmap = {};
            state.topics.forEach(topic => {
                this.topicmap[topic._id] = topic;
            });
            this.topics.innerHTML = "";
            var groups:{[key:string]:HTMLElement} = null;
            console.log("Topics:",state.topics);
            state.topics.forEach(topic=>{
                if(topic.type == TopicType.CATEGORY){
                    groups[topic._id] = this.topics.appendChild(create("optgroup", topic.name));
                }else if(topic.type == TopicType.TOPIC && !topic.parent){
                    this.topics.appendChild(create("option[value="+topic._id+"]", topic.name));
                }
            });
            state.topics.forEach(topic=>{
                if(TopicType.TOPIC && topic.parent){
                    groups[topic.parent]?.appendChild(create("option[value="+topic._id+"]", topic.name));
                }
            });

            // Setup _footer
            this._footer.innerHTML = "";
            var l = this._footer.appendChild(create("div[style=margin:0.5rem 1rem]"));
            var canvote = perms.get(Permissions.Bit.VOTE, 1);
            //if(perms.get(Permissions.Bit.VOTE, 1)){
                l.appendChild(create("span[style=margin-left:2px;margin-right:0.5rem]", "Suggestion #"+state.suggestion.number));
                var upbutton = l.appendChild(create("ion-icon[name=thumbs-up-sharp]"));
                upbutton.style.opacity = state.suggestion.votes&&state.suggestion.votes.up&&state.suggestion.votes.up.indexOf(locals.discord.id)>-1?"1":"0.67";
                var uptext = l.appendChild(create("span[style=margin-left:2px;margin-right:0.5rem]",(state.suggestion.votes&&state.suggestion.votes.up&&state.suggestion.votes.up.length||"0")+""));
                var downbutton = l.appendChild(create("ion-icon[name=thumbs-down-sharp]"));
                downbutton.style.opacity = state.suggestion.votes&&state.suggestion.votes.down&&state.suggestion.votes.down.indexOf(locals.discord.id)>-1?"1":"0.67";
                var downtext = l.appendChild(create("span[style=margin-left:2px;margin-right:0.5rem]",(state.suggestion.votes&&state.suggestion.votes.down&&state.suggestion.votes.down.length||"0")+""));
            //}
            l.appendChild(create("ion-icon[name=chatbubble-sharp]"));
            l.appendChild(create("span",state.suggestion.cachedStats.comments+""));
            var r = this._footer.appendChild(create("div[style=margin:0.5rem 1rem]"));

            if(state.suggestion.author.id == locals.discord.id || perms.get(Permissions.Bit.DELETE_SUGGESTIONS, 1)){
                var trash = r.appendChild(create("ion-icon[name=trash-sharp]"));
                trash.addEventListener("click",()=>{
                    var popup = new Popup(document.body);
                    popup.setTitle("WANRING");
                    popup.setBody(`Are you sure you want to delete the suggestion "${state.suggestion.title}"?.`);
                    popup.addButton("Delete Suggestion",()=>{
                        popup.remove();
                        var req:ClientStateRequest = {
                            view: ClientView.topics,
                            guild: state.guild.id,
                            topic: this.topics.value || undefined,
                            action:{
                                action: ClientActions.DEL_SUGGESTION,
                                data: state.suggestion
                            }
                        }
                        this.socketio.emit("state",req);
                    });
                    popup.addButton("Cancel",popup.remove);
                });
            }
        
            r.appendChild(create("span[style=margin-left:2px;margin-right:0.5rem]",`${state.suggestion.author.tag}${state.suggestion.author.nickname?" ["+state.suggestion.author.nickname+"]":""}`));
            var img = r.appendChild(create(".img"));
            img.style['background-image'] = `url(${state.suggestion.author.icon})`;

            if(canvote) upbutton.addEventListener("click",()=>{
                upbutton.style.opacity = "1";
                downbutton.style.opacity = "0.67";
                var req:ClientStateRequest = {
                    view: ClientView.suggestion,
                    guild: state.guild.id,
                    suggestion: state.suggestion._id,
                    action: {
                        action: ClientActions.UPVOTE_SUGGESTION,
                        data: state.suggestion
                    }
                };
                this.socketio.emit("state",req);
            });
            if(canvote) downbutton.addEventListener("click",()=>{
                upbutton.style.opacity = "0.67";
                downbutton.style.opacity = "1";
                var req:ClientStateRequest = {
                    view: ClientView.suggestion,
                    guild: state.guild.id,
                    suggestion: state.suggestion._id,
                    action: {
                        action: ClientActions.DOWNVOTE_SUGGESTION,
                        data: state.suggestion
                    }
                };
                this.socketio.emit("state",req);
            });

            this.search.value = state.suggestion.title;
            this.topics.value = state.suggestion.topic;
            this.description.value = state.suggestion.description;
            textFieldAutoSize.call(this.description);
        }

        // Setup this.page Comments Section
        if(!this.editingcomment){
            this.page.innerHTML = "";
            this.state.comments.forEach(comment=>{
                this.page.appendChild(renderComment(this, comment));
            });
        }

        // Setup Similar Section
        this.similarpage.innerHTML = "";
        if(this.state.similarsuggestions.length){
            this.similarpage.appendChild(create("h4[style=margin-bottom:0;background-color:var(--foreground-color);padding0 2px;]","Similar Suggestions"));
        }
        this.state.similarsuggestions.forEach((suggestion, i)=>{
            this.similarpage.appendChild(renderSuggestion(this, suggestion));
        });
    }
    onDestroy(){}
}

function renderComment(view:SuggestionView, comment:Comment):HTMLElement{
    var perms = compilePermissionsResults(view.state.myroles, view.state.roles, view.topicmap[view.state.suggestion.topic],view.topicmap[view.topicmap[view.state.suggestion.topic].parent]);
    var c = create(".suggestion");

    var fixed_description = c.appendChild(create(".suggestion-description", comment.description));
    var dynmc_description = c.appendChild(create("textarea.suggestion-description[rows=1,style=display:none]", comment.description)) as HTMLTextAreaElement;
    dynmc_description.value = comment.description;
    textFieldAutoSize.call(dynmc_description);
    dynmc_description.addEventListener("input",textFieldAutoSize);

    var f = c.appendChild(create(".suggestion-footer"));
    var l = f.appendChild(create(""));
    var canvote = perms.get(Permissions.Bit.VOTE, 1);
    //if(perms.get(Permissions.Bit.VOTE, 1)){
        var upbutton = l.appendChild(create("ion-icon[name=thumbs-up-sharp]"));
        upbutton.style.opacity = comment.votes&&comment.votes.up.indexOf(locals.discord.id)>-1?"1":"0.67";
        var uptext = l.appendChild(create("span[style=margin-left:2px;margin-right:0.5rem]",(comment.votes&&comment.votes.up&&comment.votes.up.length||"0")+""));
        var downbutton = l.appendChild(create("ion-icon[name=thumbs-down-sharp]"));
        downbutton.style.opacity = comment.votes&&comment.votes.down.indexOf(locals.discord.id)>-1?"1":"0.67";
        var downtext = l.appendChild(create("span[style=margin-left:2px;margin-right:0.5rem]",(comment.votes&&comment.votes.down&&comment.votes.down.length||"0")+""));
    //}
    if(comment.author.id == locals.discord.id || perms.get(Permissions.Bit.DELETE_COMMENTS, 1)){
        var editbutton = l.appendChild(create("ion-icon[name=create-sharp]"));
        var savebutton = l.appendChild(create("ion-icon[name=save-sharp]"));
        savebutton.style.display = "none";
        editbutton.addEventListener("click", ()=>{
            if(view.editingcomment) return;
            view.editingcomment = true;
            dynmc_description.style.display = "";
            fixed_description.style.display = "none";
            trash.style.display = "none";
            dynmc_description.focus();
            editbutton.style.display = "none";
            savebutton.style.display = "";
            textFieldAutoSize.call(dynmc_description);
            view.createcommentbutton.style.display = "none";
        });
        savebutton.addEventListener("click", ()=>{
            if(!view.editingcomment || savebutton.style.display == "none") return;
            view.editingcomment = false;
            dynmc_description.style.display = "none";
            fixed_description.innerText = dynmc_description.value;
            fixed_description.style.display = "";
            trash.style.display = "";
            editbutton.style.display = "";
            savebutton.style.display = "none";
            view.createcommentbutton.style.display = "";
            comment.description = dynmc_description.value;
            var req:ClientStateRequest = {
                view: ClientView.suggestion,
                guild: view.state.guild.id,
                suggestion: view.state.suggestion._id,
                action:{
                    action: ClientActions.EDIT_COMMENT,
                    data: comment
                }
            }
            loading("rendering",true);
            view.socketio.emit("state",req);
        });
    }

    var r = f.appendChild(create(""));
    if(comment.author.id == locals.discord.id || perms.get(Permissions.Bit.DELETE_COMMENTS, 1)){
        var trash = r.appendChild(create("ion-icon[name=trash-sharp]"));
        trash.addEventListener("click",()=>{
            var popup = new Popup(document.body);
            popup.setTitle("WANRING");
            popup.setBody(`Are you sure you want to delete the coment "${comment.description}"?.`);
            popup.addButton("Delete Comment",()=>{
                popup.remove();
                var req:ClientStateRequest = {
                    view: ClientView.suggestion,
                    guild: view.state.guild.id,
                    suggestion: view.state.suggestion._id,
                    action:{
                        action: ClientActions.DEL_COMMENT,
                        data: comment
                    }
                }
                loading("rendering",true);
                view.socketio.emit("state",req);
            });
            popup.addButton("Cancel",popup.remove);
        });
    }

    r.appendChild(create("span[style=margin-left:2px;margin-right:0.5rem]",`${comment.author.tag}${comment.author.nickname?" ["+comment.author.nickname+"]":""}`));
    var img = r.appendChild(create(".img"));
    img.style['background-image'] = `url(${comment.author.icon})`;

    if(canvote) upbutton.addEventListener("click",()=>{
        upbutton.style.opacity = "1";
        downbutton.style.opacity = "0.67";
        var req:ClientStateRequest = {
            view: ClientView.suggestion,
            guild: view.state.guild.id,
            suggestion: view.state.suggestion._id,
            action: {
                action: ClientActions.UPVOTE_COMMENT,
                data: comment
            }
        };
        view.socketio.emit("state",req);
    });
    if(canvote) downbutton.addEventListener("click",()=>{
        upbutton.style.opacity = "0.67";
        downbutton.style.opacity = "1";
        var req:ClientStateRequest = {
            view: ClientView.suggestion,
            guild: view.state.guild.id,
            suggestion: view.state.suggestion._id,
            action: {
                action: ClientActions.DOWNVOTE_COMMENT,
                data: comment
            }
        };
        view.socketio.emit("state",req);
    });



    return c;
}
function renderSuggestion(view:SuggestionView, suggestion:Suggestion):HTMLElement{
    var perms = compilePermissionsResults(view.state.myroles, view.state.roles, view.topicmap[suggestion.topic],view.topicmap[view.topicmap[suggestion.topic].parent]);
    var c = create(".suggestion");
    c.appendChild(create(
        ".suggestion-header", 
        "#"+suggestion.number+" - "+(view.topicmap[suggestion.topic]&&view.topicmap[suggestion.topic].parent&&view.topicmap[view.topicmap[suggestion.topic].parent]?view.topicmap[view.topicmap[suggestion.topic].parent].name+"/":"")+(view.topicmap[suggestion.topic] || {name:"???"}).name
    ));
    var a = c.appendChild(create("a.suggestion-title[style=text-decoration:none]", suggestion.title)) as HTMLLinkElement;
    a.href = "/"+suggestion.guild+"/"+suggestion.topic+"/"+suggestion._id;
    a.addEventListener("click",(e)=>{
        e.preventDefault();
        var req:ClientStateRequest = {
            view: ClientView.suggestion,
            guild: view.state.guild.id,
            suggestion: suggestion._id,
        }
        loading("rendering",true);
        view.socketio.emit("state",req);
    });

    c.appendChild(create(".suggestion-description", suggestion.description));
    var f = c.appendChild(create(".suggestion-footer"));
    var l = f.appendChild(create(""));

    var canvote = perms.get(Permissions.Bit.VOTE, 1);
    //if(perms.get(Permissions.Bit.VOTE, 1)){
        var upbutton = l.appendChild(create("ion-icon[name=thumbs-up-sharp]"));
        upbutton.style.opacity = suggestion.votes&&suggestion.votes.up&&suggestion.votes.up.indexOf(locals.discord.id)>-1?"1":"0.67";
        var uptext = l.appendChild(create("span[style=margin-left:2px;margin-right:0.5rem]",(suggestion.votes&&suggestion.votes.up&&suggestion.votes.up.length||"0")+""));
        var downbutton = l.appendChild(create("ion-icon[name=thumbs-down-sharp]"));
        downbutton.style.opacity = suggestion.votes&&suggestion.votes.down&&suggestion.votes.down.indexOf(locals.discord.id)>-1?"1":"0.67";
        var downtext = l.appendChild(create("span[style=margin-left:2px;margin-right:0.5rem]",(suggestion.votes&&suggestion.votes.down&&suggestion.votes.down.length||"0")+""));
    //}
    l.appendChild(create("ion-icon[name=chatbubble-sharp]"));
    l.appendChild(create("span",suggestion.cachedStats.comments+""));
    var r = f.appendChild(create(""));
    
    if(suggestion.author.id == locals.discord.id || perms.get(Permissions.Bit.DELETE_SUGGESTIONS, 1)){
        var trash = r.appendChild(create("ion-icon[name=trash-sharp]"));
        trash.addEventListener("click",()=>{
            var popup = new Popup(document.body);
            popup.setTitle("WANRING");
            popup.setBody(`Are you sure you want to delete the suggestion "${suggestion.title}"?.`);
            popup.addButton("Delete Suggestion",()=>{
                popup.remove();
                var req:ClientStateRequest = {
                    view: ClientView.suggestion,
                    guild: view.state.guild.id,
                    suggestion: view.state.suggestion._id,
                    action:{
                        action: ClientActions.DEL_SUGGESTION,
                        data: suggestion
                    }
                }
                view.socketio.emit("state",req);
            });
            popup.addButton("Cancel",popup.remove);
        });
    }

    r.appendChild(create("span[style=margin-left:2px;margin-right:0.5rem]",`${suggestion.author.tag}${suggestion.author.nickname?" ["+suggestion.author.nickname+"]":""}`));
    var img = r.appendChild(create(".img"));
    img.style['background-image'] = `url(${suggestion.author.icon})`;

    if(canvote) upbutton.addEventListener("click",()=>{
        upbutton.style.opacity = "1";
        downbutton.style.opacity = "0.67";
        var req:ClientStateRequest = {
            view: ClientView.suggestion,
            guild: view.state.guild.id,
            suggestion: view.state.suggestion._id,
            action: {
                action: ClientActions.UPVOTE_SUGGESTION,
                data: suggestion
            }
        };
        view.socketio.emit("state",req);
    });
    if(canvote) downbutton.addEventListener("click",()=>{
        upbutton.style.opacity = "0.67";
        downbutton.style.opacity = "1";
        var req:ClientStateRequest = {
            view: ClientView.suggestion,
            guild: view.state.guild.id,
            suggestion: view.state.suggestion._id,
            action: {
                action: ClientActions.DOWNVOTE_SUGGESTION,
                data: suggestion
            }
        };
        view.socketio.emit("state",req);
    });
    return c;
}