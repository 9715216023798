import { create } from "./api"

export class Popup {
    parent:HTMLElement
    container:HTMLElement
    inner:HTMLElement
    popup:HTMLElement
    title:HTMLElement
    body:HTMLElement
    content:HTMLElement
    constructor(parent:HTMLElement){
        this.parent = parent;
        this.container = parent.appendChild(create(".popup-dialog"));
        this.inner = this.container.appendChild(create(".popup-dialog-inner"));
        this.popup = this.inner.appendChild(create(".popup-inner"));
        this.title = this.popup.appendChild(create("h3.center"));
        this.body = this.popup.appendChild(create("p.center"));
        this.content = this.popup.appendChild(create("div[style=position:relative;width:100%]"))
        this.setTitle = this.setTitle.bind(this);
        this.setBody = this.setBody.bind(this);
        this.addButton = this.addButton.bind(this);
        this.remove = this.remove.bind(this);
    }
    setTitle(title: string){
        this.title.innerText = title;
    }
    setBody(body: string){
        this.body.innerText = body;
    }
    addButton(text:string, method:Function):HTMLElement{
        var elem = this.popup.appendChild(create("button.popup-button", text));
        elem.addEventListener("click", ()=>method());
        return elem
    }
    remove(){
        this.container.remove();
    }
}